import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-water',
  templateUrl: './water.component.html',
  styleUrls: ['./water.component.scss']
})
export class WaterComponent implements OnInit {
  waterSelectedNext:boolean=false;
  waterSelectedBack: boolean=true;
  water: {};
  waterOptionSelected:any;
  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    this.water={}
    this.water['questionid']=localStorage.getItem('waterQustionId')
    this.water['question']=localStorage.getItem('waterQuestion')
   this.water['answer']=localStorage.getItem('waterAnswer')
   this.water['waterValue']=localStorage.getItem('waterValue')
   this.waterOptionSelected=localStorage.getItem('waterAnswer')
   this.dataservice.waterdata=this.water
   if(localStorage.getItem('waterAnswer')=="Never" || localStorage.getItem('waterAnswer')=="Rarely" || localStorage.getItem('waterAnswer')=="Sometimes" ||localStorage.getItem('waterAnswer')=="Always")
   {
     this.waterSelectedNext=true
   }
   else{
     this.waterSelectedNext=false
   }
  
  }
  waterBack()
  {
this.route.navigate(['assessment/11'])
  }
  waterNext()
  {
this.route.navigate(['assessment/13'])
this.water={}
    this.water['questionid']=localStorage.getItem('waterQustionId')
    this.water['question']=localStorage.getItem('waterQuestion')
   this.water['answer']=localStorage.getItem('waterAnswer')

   this.waterOptionSelected=localStorage.getItem('waterAnswer')
   this.dataservice.waterdata=this.water
  }
  waterOption1()
  {
    localStorage.setItem('waterQustionId', "12")
    localStorage.setItem('waterQuestion', "HDo you drink 8 or more glasses of water each day?")
    localStorage.setItem('waterAnswer', "Never")
    localStorage.setItem('waterValue', "0")
    this.waterOptionSelected="Never"    
    this.waterSelectedNext=true;
  }
  waterOption2()
  {
    localStorage.setItem('waterQustionId', "12")
    localStorage.setItem('waterQuestion', "HDo you drink 8 or more glasses of water each day?")
    localStorage.setItem('waterAnswer', "Rarely")
    localStorage.setItem('waterValue', "0")
    this.waterOptionSelected="Rarely"    
    this.waterSelectedNext=true;
  }
  waterOption3()
  {
    localStorage.setItem('waterQustionId', "12")
    localStorage.setItem('waterQuestion', "HDo you drink 8 or more glasses of water each day?")
    localStorage.setItem('waterAnswer', "Sometimes")
    localStorage.setItem('waterValue', "0")
    this.waterOptionSelected="Sometimes"    
    this.waterSelectedNext=true;
  }
  waterOption4()
  {
    localStorage.setItem('waterQustionId', "12")
    localStorage.setItem('waterQuestion', "HDo you drink 8 or more glasses of water each day?")
    localStorage.setItem('waterAnswer', "Always")
    localStorage.setItem('waterValue', "-1")
    this.waterOptionSelected="Always"    
    this.waterSelectedNext=true;
  }


}
