
<head>
  <title>Artistry</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>

</head>
<body>
<nav class="navbar">
  <div class="container">
    <a href="home"><img src="../../assets/images/artistry_logo_header.png" height="45px"/></a>
  </div>
</nav>

<div class="container page-content">
<div class="row">
  <div class="col-sm-12 col-md-8">
    <!--  <form [formGroup]="questionsForm" (ngSubmit)="assessmentReview()">-->
<div *ngIf="ageAssessment">
    <div class="question-section">
        <h3>Please enter your birth year.</h3>
    </div>

    <div class="answer-section" >
        <div class="select-design">
            <select class="form-control" [(ngModel)]="ageselectedValue"  (change)="ageSelection($event)" required>
                <option>Select Birth Year</option>
                <option *ngFor="let birthyear of birthYear" [value]="birthyear">{{birthyear}}</option>
            </select>
        </div>
        
    </div>
    <div class="next-back" *ngIf="ageSelected" >
       
        <button class="float-right next"  (click)="ageSelectionNext()">Next</button>
    </div>
</div>



</div>
  
</div>
</div>


<footer class="container-fluid text-center">
    <div class="col-sm-7 p-20-0">
        <p>
            Amway India
        </p>
        <p>
            Copyright © 2018 Amway India. All rights are Reserved
        </p>
    </div>
    <div class="col-sm-5"><img src="../../assets/images/Art_Log_3D_05C_sRGB_white.png" height="65px" class="img-responsive m-a"/></div>
</footer>

</body>
