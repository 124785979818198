import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AssessmentComponent } from './assessment/assessment.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SkinconditionComponent } from './skincondition/skincondition.component';

//import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { SkinfeelComponent } from './skinfeel/skinfeel.component';
import { SunprotectionComponent } from './sunprotection/sunprotection.component';
import { SunexposeComponent } from './sunexpose/sunexpose.component';
import { SkincareComponent } from './skincare/skincare.component';
import { AntiskincareComponent } from './antiskincare/antiskincare.component';
import { StressComponent } from './stress/stress.component';
import { SleepComponent } from './sleep/sleep.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { PollutionComponent } from './pollution/pollution.component';
import { WaterComponent } from './water/water.component';
import { RealageComponent } from './realage/realage.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TermsComponent } from './terms/terms.component';
import { PrivacyComponent } from './privacy/privacy.component';
import { HashLocationStrategy, LocationStrategy,PathLocationStrategy  } from '@angular/common';
import { CustomInterceptor } from './appinitservice';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    AssessmentComponent,
    RecommendationsComponent,
    SkinconditionComponent,
 
    SkinfeelComponent,
    SunprotectionComponent,
    SunexposeComponent,
    SkincareComponent,
    AntiskincareComponent,
    StressComponent,
    SleepComponent,
    ExerciseComponent,
    PollutionComponent,
    WaterComponent,
    RealageComponent,
    TermsComponent,
    PrivacyComponent
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    // BackButtonDisableModule.forRoot({
    //   preserveScrollPosition: true
    // })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: CustomInterceptor ,
    multi: true
  }],
  
  
  bootstrap: [AppComponent]
})
export class AppModule { }
