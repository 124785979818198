import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-skinfeel',
  templateUrl: './skinfeel.component.html',
  styleUrls: ['./skinfeel.component.scss']
})
export class SkinfeelComponent implements OnInit {
  skinFeelAssessment:boolean =true;
  skinFeelSelectedBack:boolean= true;
  skinFeelSelectedNext:boolean = false;
  skinFeel: {};
  
  sunProtectionAssessment: boolean;
  skinConditionAssessment: boolean;
  skinFeelValue: string;
  skinDry: boolean = false;
  skinOily: boolean = false;
  skinCombo: boolean = false;
  skinNormal: boolean = false;
  skinNoOption: boolean =true;

  constructor(private fb: FormBuilder,  private route: Router, private dataservice: dataService) { }


  ngOnInit(): void {
    this.skinFeel={}
    this.skinFeel['questionid']=localStorage.getItem('skinFeelQustionId')
    this.skinFeel['question']=localStorage.getItem('skinFeelQuestion')
   this.skinFeel['answer']=localStorage.getItem('skinFeelAnswer')
    this.skinFeel['skinFeel']=localStorage.getItem('skinFeel')
   this.skinFeelValue=localStorage.getItem('skinFeelAnswer')
   this.dataservice.skinfeeldata=this.skinFeel
   
    if(localStorage.getItem('skinFeelAnswer')=="My whole face feels dry and uncomfortable")
    {
      this.skinFeelValue="My whole face feels dry and uncomfortable"
      this.skinDry=true
      this.skinNormal=false
      this.skinCombo=false
      this.skinOily=false
      this.skinNoOption=false
      this.skinFeelSelectedNext=true
    }else if(localStorage.getItem('skinFeelAnswer')=="My whole face feels comfortable")
    {
      this.skinFeelValue="My whole face feels comfortable"
      this.skinDry=false
      this.skinNormal=true
      this.skinCombo=false
      this.skinOily=false
      
      this.skinNoOption=false
      this.skinFeelSelectedNext=true
    }else if(localStorage.getItem('skinFeelAnswer')=="My cheeks feel dry while my forehead/nose/chin feel oily")
    {
      this.skinFeelValue="My cheeks feel dry while my forehead/nose/chin feel oily"
      this.skinDry=false
      this.skinNormal=false
      this.skinCombo=true
      this.skinOily=false
      
      this.skinNoOption=false
      this.skinFeelSelectedNext=true
    }else if(localStorage.getItem('skinFeelAnswer')=="My whole face feels oily and looks shiny")
    {
      this.skinFeelValue="My whole face feels oily and looks shiny"
      this.skinDry=false
      this.skinNormal=false
      this.skinCombo=false
      this.skinOily=true
      
      this.skinNoOption=false
      this.skinFeelSelectedNext=true
    }else{
      
      this.skinNoOption=true
      this.skinFeelSelectedNext=false
    }
  }
  skinFeelOption1()
  {
    localStorage.setItem('skinFeelQustionId', "3")
    localStorage.setItem('skinFeelQuestion', "How does your skin feel in the middle of the day?")
    localStorage.setItem('skinFeelAnswer', "My whole face feels dry and uncomfortable")
    localStorage.setItem('skinFeel', "Dry" )
    this.skinDry=true
    this.skinNormal=false
    this.skinCombo=false
    this.skinOily=false
    this.skinNoOption=false 
    this.skinFeelSelectedNext=true
    this.skinFeelValue="My whole face feels dry and uncomfortable"
  
  }
  skinFeelOption2()
  {
    localStorage.setItem('skinFeelQustionId', "3")
    localStorage.setItem('skinFeelQuestion', "How does your skin feel in the middle of the day?")
    localStorage.setItem('skinFeelAnswer', "My whole face feels comfortable")
    localStorage.setItem('skinFeel', "Normal")
    this.skinDry=false
      this.skinNormal=true
      this.skinCombo=false
      this.skinOily=false
      
      this.skinNoOption=false
 
    this.skinFeelSelectedNext=true
    this.skinFeelValue="My whole face feels comfortable"
  
  }
  skinFeelOption3()
  {
    localStorage.setItem('skinFeelQustionId', "3")
    localStorage.setItem('skinFeelQuestion', "How does your skin feel in the middle of the day?")
    localStorage.setItem('skinFeelAnswer', "My cheeks feel dry while my forehead/nose/chin feel oily")
    localStorage.setItem('skinFeel', "Dry and Oily")
    this.skinDry=false
      this.skinNormal=false
      this.skinCombo=true
      this.skinOily=false
      
      this.skinNoOption=false
  
    
    this.skinFeelSelectedNext=true
    this.skinFeelValue="My whole face feels oily and looks shiny"
  
  }
  skinFeelOption4()
  {
    localStorage.setItem('skinFeelQustionId', "3")
    localStorage.setItem('skinFeelQuestion', "How does your skin feel in the middle of the day?")
    localStorage.setItem('skinFeelAnswer', "My whole face feels oily and looks shiny")
    localStorage.setItem('skinFeel', "Oily")
    this.skinDry=false
    this.skinNormal=false
    this.skinCombo=false
    this.skinOily=true
    this.skinNoOption=false
    
    
    this.skinFeelSelectedNext=true
    this.skinFeelValue="My whole face feels oily and looks shiny"

  
  }
  
  
  skinFeelNext()
  {
   
   this.route.navigate(['assessment/4'])
   this.skinFeel={}
    this.skinFeel['questionid']=localStorage.getItem('skinFeelQustionId')
    this.skinFeel['question']=localStorage.getItem('skinFeelQuestion')
   this.skinFeel['answer']=localStorage.getItem('skinFeelAnswer')
   this.skinFeel['skinFeel']=localStorage.getItem('skinFeel')
   this.skinFeelValue=localStorage.getItem('skinFeelAnswer')
   this.dataservice.skinfeeldata=this.skinFeel
   
 
  
  }
  
    skinFeelBack()
    {
      this.route.navigate(['assessment/2'])
      
    }
}
