import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-pollution',
  templateUrl: './pollution.component.html',
  styleUrls: ['./pollution.component.scss']
})
export class PollutionComponent implements OnInit {
  pollution: {};
  pollutionOptionSelected:any;
  pollutionSelectedBack:boolean=true;
  pollutionSelectedNext:boolean=false;
  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    this.pollution={}
    this.pollution['questionid']=localStorage.getItem('pollutionQustionId')
    this.pollution['question']=localStorage.getItem('pollutionQuestion')
   this.pollution['answer']=localStorage.getItem('pollutionAnswer')
   this.pollutionOptionSelected=localStorage.getItem('pollutionAnswer')
   this.dataservice.pollutiondata=this.pollution
   if(localStorage.getItem('pollutionAnswer')=="Never" || localStorage.getItem('pollutionAnswer')=="Rarely" || localStorage.getItem('pollutionAnswer')=="Sometimes" ||localStorage.getItem('pollutionAnswer')=="Always")
   {
     this.pollutionSelectedNext=true
   }
   else{
     this.pollutionSelectedNext=false
   }
  
  }
  pollutionBack()
  {
this.route.navigate(['assessment/10'])

  }
pollutionNext()
{
this.route.navigate(['assessment/12'])
this.pollution={}
    this.pollution['questionid']=localStorage.getItem('pollutionQustionId')
    this.pollution['question']=localStorage.getItem('pollutionQuestion')
   this.pollution['answer']=localStorage.getItem('pollutionAnswer')
   this.pollution['pollutionValue']=localStorage.getItem('pollutionValue')
   this.pollutionOptionSelected=localStorage.getItem('pollutionAnswer')
   this.dataservice.pollutiondata=this.pollution
}
pollutionOption1()
{
  localStorage.setItem('pollutionQustionId', "11")
  localStorage.setItem('pollutionQuestion', "How often are you exposed to pollution?")
  localStorage.setItem('pollutionAnswer', "Never")
  localStorage.setItem('pollutionValue', "-1")
  this.pollutionOptionSelected="Never"    
  this.pollutionSelectedNext=true;
}
pollutionOption2()
{
  localStorage.setItem('pollutionQustionId', "11")
  localStorage.setItem('pollutionQuestion', "How often are you exposed to pollution?")
  localStorage.setItem('pollutionAnswer', "Rarely")
  localStorage.setItem('pollutionValue', "0")
  this.pollutionOptionSelected="Rarely"    
  this.pollutionSelectedNext=true;
}
pollutionOption3()
{
  localStorage.setItem('pollutionQustionId', "11")
  localStorage.setItem('pollutionQuestion', "How often are you exposed to pollution?")
  localStorage.setItem('pollutionAnswer', "Sometimes")
  localStorage.setItem('pollutionValue', "1")
  this.pollutionOptionSelected="Sometimes"    
  this.pollutionSelectedNext=true;
}
pollutionOption4()
{
  localStorage.setItem('pollutionQustionId', "11")
  localStorage.setItem('pollutionQuestion', "How often are you exposed to pollution?")
  localStorage.setItem('pollutionAnswer', "Always")
  localStorage.setItem('pollutionValue', "1")
  this.pollutionOptionSelected="Always"    
  this.pollutionSelectedNext=true;
}
}
