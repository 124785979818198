<head>
  <title>Artistry</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
 
</head>
<body>

<nav class="navbar">
  <div class="container">
    <a href="home"><img src="../../assets/images/artistry_logo_header.png" height="45px"/></a>
  </div>
</nav>

<div class="container page-content">
    <div class="row">
      <div class="col-sm-12 col-md-8">

        <div class="question-section">
            <h3>Age of your skin compared to your real age</h3>
        </div>


        <div class="bg-white p15 m-t-5">
        <div>
            <p>
                The colored bar on this chart shows how your actual age compares to your skin's age, based on the conditions that affect its appearance. The concerns arrows on the chart each show the age range they affect. 
            </p>
        </div>


<div class="graph-area">
        <h3 class="header header-x-axis up"><strong>Real Age vs Skin Age</strong></h3>

<div class="skills">
    <div class="container-arrow"  style="margin-left: 28%;width: 42.6%;">
        <div class="container-arrow-inner">
            <strong>FINE LINE WRINKLES</strong>
            <div class="arrow"></div>
        </div>
    </div>
    <div class="container-arrow"  style="margin-left: 35%; width: 42.6%;">
        <div class="container-arrow-inner">
            <strong>LOSS OF ELASTICITY/SAGGING</strong>
            <div class="arrow"></div>
        </div>
    </div>
    <div class="container-arrow"  style="margin-left: 18.5714%; width: 30.8571%;">
        <div class="container-arrow-inner">
            <strong>PIGMENTATION</strong>
            <div class="arrow"></div>
        </div>
    </div>
    <div class="container-arrow"  style="margin-left: 35%; width: 21.6%;">
        <div class="container-arrow-inner">
            <strong>DRYNESS</strong>
            <div class="arrow"></div>
        </div>
    </div>
    <div class="container-arrow"  style="margin-left: 30%; width: 14.2857%;">
        <div class="container-arrow-inner">
            <strong>UNEVEN TONE</strong>
            <div class="arrow"></div>
        </div>
    </div>
    <div class="container-arrow"  style="margin-left: 21.4286%; width: 14.2857%;">
        <div class="container-arrow-inner">
            <strong>LOSS OF RADIANCE</strong>
            <div class="arrow"></div>
        </div>
    </div>
    <div class="container-arrow"  style="margin-left: 25.7143%; width: 21.4286%;">
        <div class="container-arrow-inner">
            <strong>VISIBLE PORES</strong>
            <div class="arrow"></div>
        </div>
    </div>
    <div class="container-arrow"  style="margin-left: 21.4286%; width: 18.5714%;">
        <div class="container-arrow-inner">
            <strong>ROUGH TEXTURE</strong>
            <div class="arrow"></div>
        </div>
    </div>
    
    <ul class="lines">
        <div id="chip">
            Skin Age: {{skinage}}

            <span class="glyphicon glyphicon-arrow-up"></span>
         </div>

         <div id="tooltip">
            Skin Age: {{skinage}}
         </div>
        <li class="line" id="greenline">
        </li>
    
       
        <li   class="line" id="yellowline">
        </li>
        
 
        <li class="line ageline"  >
            
    
            
            
        </li>
      <li class="line l--0">
      </li>
      <li class="line l--14">
      
      </li>
      <li class="line l--28">
       
      </li>
      <li class="line l--42">
       
      </li>
      <li class="line l--57">
       
      </li>
      <li class="line l--71">
       
      </li>
      <li class="line l--86">
            
      </li>
      <li class="line l--100">
           
      </li>
    </ul>
    <ul class="line-label">
        <li class="line">
        10
         
        </li>
        <li class="line">
         20
        </li>
        <li class="line">
         30
        </li>
        <li class="line">
         40
        </li>
        <li class="line">
         50
        </li>
        <li class="line">
         60
        </li>
        <li class="line">
         70   
        </li>
      </ul>
  </div>
  <h3 class="header header-x-axis up"><strong>Real Age vs Skin Age</strong></h3>
</div>
  <div >


    <div class="realagechart-legend">
        <div class="row">
            <div class="col-sm-3">
                <div class="media">
                    <div class="container-line">
                        <div class="realage-line">
                        </div>
                    </div>
                    <div class="media-body">
                              <p style="display:inline-block">Real Age</p>
                         <span style="color: #a89275;" class="ng-binding"> - {{currentAge}}</span>
                    </div>
                </div>
            </div>
            <div class="col-sm-3">
                <div class="media">
                    <span *ngIf="yellowLine">
                        <div class="container-line">
                            <div class="skinage-line skinage-line-negative">
                            </div>
                        </div>
                        <div class="media-body">
                            <p style="display:inline-block">Skin Age</p>
                             <span style="color: #a89275;" class="ng-binding"> - {{skinage}}</span>
                        </div>
                    </span>
                    <span *ngIf="greenLine">
                    <div class="container-line">
                        <div class="skinage-line skinage-line-positive">
                        </div>
                    </div>
                    <div class="media-body">
                        <p style="display:inline-block">Skin Age</p>
                         <span style="color: #a89275;" class="ng-binding"> - {{skinage}}</span>
                    </div>
                    </span>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="media legend">
                    <div class="container-arrow-legend">
                        <div class="container-arrow">
                            <div class="container-arrow-inner">
                                <div class="arrow"></div>
                            </div>
                        </div>
                    </div>
                    <div class="media-body arrow-legend-text">
                        <p class="arrow-up-left">Damage Begins</p>
                        <p class="arrow-up-right">Consumer notices</p>
                    </div>
                </div>
            </div>
        </div>
    </div>




      <p *ngIf="negative"> Because your skin age is higher than your real age, it shows that you are overlooking ways to proactively manage your skin health. You are encouraged to recognize areas where you could improve your skin age, including eating a healthy diet, sleeping eight hours regularly, and staying out of the sun. Consider following an Artistry Skincare anti-aging routine to fight visible and premature signs of aging. Pass the test a few times per year to meet needs of your skin in different year periods.</p>
  <p *ngIf="positive"> Your skin age is in good shape right now and you could keep and improve your skin age by eating a healthy diet, sleeping eight hours regularly, and staying out of the sun. Consider following an Artistry Skincare routine to fight visible and premature signs of aging. Pass the test a few times per year to meet needs of your skin in different year periods.</p>
    </div>
  

</div>
<div class="next-back">
    <button class="float-left back" *ngIf="realAgeSelectedBack" (click)="realAgeBack()">Back</button>
    <button class="float-right next" *ngIf="realAgeSelectedNext" (click)="realAgeNext()">Next</button>
  </div>
  </div>
  <div class="col-sm-12 col-md-4">
    <div class="progress">
        <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width:100%">
          <span>100%</span>
        </div>
      </div>
      
  </div>
    </div>


   


</div>
  

  <footer class="container-fluid text-center">
    <div class="col-sm-7 p-20-0">
        <p>
            Amway India
        </p>
        <p>
            Copyright © 2018 Amway India. All rights are Reserved
        </p>
    </div>
    <div class="col-sm-5"><img src="../../assets/images/Art_Log_3D_05C_sRGB_white.png" height="65px" class="img-responsive m-a"/></div>
</footer>

</body>