import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormControlName, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from "../dataservice.service"

@Component({
  selector: 'app-skincondition',
  templateUrl: './skincondition.component.html',
  styleUrls: ['./skincondition.component.scss']
})
export class SkinconditionComponent implements OnInit {
  questionSkinForm: FormGroup;
  skinConditionAssessment: boolean = true;
  skinConditionSelectedNext: boolean = false;
  skinConditionSelectedBack: boolean = true;
  skinConditionOpt: any;
  skincondition: {};
  submitted: boolean;

 

 


  constructor(private fb: FormBuilder,  private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    
    this.skincondition={}
    this.skincondition["questionid"]=localStorage.getItem('skinConditionQuestionId')
    this.skincondition["question"]=localStorage.getItem('skinConditionQuestion')
    this.skincondition["answer"]=localStorage.getItem('skinConditionAnswer')
    
    this.dataservice.skinconditiondata=this.skincondition

    
this.initializeQuestions()

  }
  initializeQuestions() {
    if(localStorage.getItem('skinConditionAnswer')==null)
    {
      this.skinConditionOpt=null
      this.skinConditionSelectedNext = false;
    }
    else 
    {
      this.skinConditionOpt=localStorage.getItem('skinConditionAnswer')
      this.skinConditionSelectedNext = true;

    }
   
  }
 
  skinConditionBack()
  {
    
   this.route.navigate(['assessment'])
    this.skinConditionAssessment=false
  }
  skinConditionNext()
  {
    this.route.navigate(['assessment/3'])
   
    this.skincondition={}
    this.skincondition["questionid"]=localStorage.getItem('skinConditionQuestionId')
    this.skincondition["question"]=localStorage.getItem('skinConditionQuestion')
    this.skincondition["answer"]=localStorage.getItem('skinConditionAnswer')
    
    this.dataservice.skinconditiondata=this.skincondition
    this.skinConditionAssessment=false
   // this.skinFeelAssessment=true
  }

  clearSkinCondition()
  {

    this.skinConditionOpt=null
    // localStorage.removeItem('skinConditionQuestionId')
    // localStorage.removeItem('skinConditionQuestion')
    // localStorage.removeItem('skinConditionAnswer')
    
    this.skinConditionSelectedNext=false

  }
  skinConditionOption1()
  {
    this.skinConditionOpt="Discoloration/Dark spots, Uneven Skintone and Loss of Radiance"
    localStorage.setItem('skinConditionQuestionId', "2")
    localStorage.setItem('skinConditionQuestion', "Identify one main concern of your skin condition")
    localStorage.setItem('skinConditionAnswer', this.skinConditionOpt)
   
   
   
    
    this.skinConditionSelectedNext=true
    
  }
  skinConditionOption2()
  {
    this.skinConditionOpt="Fine Lines and Wrinkles"
    localStorage.setItem('skinConditionQuestionId', "2")
    localStorage.setItem('skinConditionQuestion', "Identify one main concern of your skin condition")
    localStorage.setItem('skinConditionAnswer', this.skinConditionOpt)
   
   
    
   
 
   
    this.skinConditionSelectedNext=true
  }
  skinConditionOption3()
  {
    this.skinConditionOpt="Dehydration and Dryness"
    localStorage.setItem('skinConditionQuestionId', "2")
    localStorage.setItem('skinConditionQuestion', "Identify one main concern of your skin condition")
    localStorage.setItem('skinConditionAnswer', this.skinConditionOpt)
   
   
    
   
    
    this.skinConditionSelectedNext=true
  }
  skinConditionOption4()
  {
    this.skinConditionOpt="Uneven Texture"
    localStorage.setItem('skinConditionQuestionId', "2")
    localStorage.setItem('skinConditionQuestion', "Identify one main concern of your skin condition")
    localStorage.setItem('skinConditionAnswer', this.skinConditionOpt)
   
   
    
    
    
    this.skinConditionSelectedNext=true
  }
  skinConditionOption5()
  {
    this.skinConditionOpt="Visible Pores"
    localStorage.setItem('skinConditionQuestionId', "2")
    localStorage.setItem('skinConditionQuestion', "Identify one main concern of your skin condition")
    localStorage.setItem('skinConditionAnswer', this.skinConditionOpt)
   
   
   
    
    this.skinConditionSelectedNext=true
  }
  skinConditionOption6()
  {
    this.skinConditionOpt="Loss of Elasticity and sagging"
    localStorage.setItem('skinConditionQuestionId', "2")
    localStorage.setItem('skinConditionQuestion', "Identify one main concern of your skin condition")
    localStorage.setItem('skinConditionAnswer', this.skinConditionOpt)
   
   
    
    
    this.skinConditionSelectedNext=true
  }
  assessmentReview() {
    this.submitted = true

  }
  


}
