import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-exercise',
  templateUrl: './exercise.component.html',
  styleUrls: ['./exercise.component.scss']
})
export class ExerciseComponent implements OnInit {
  exerciseOptionSelected:any;
  exercise: {};
  exerciseSelectedNext:boolean=false;
  exerciseSelectedBack:boolean=true;
  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }
  
  ngOnInit(): void {
    this.exercise={}
    this.exercise['questionid']=localStorage.getItem('exerciseQustionId')
    this.exercise['question']=localStorage.getItem('exerciseQuestion')
   this.exercise['answer']=localStorage.getItem('exerciseAnswer')
   this.exercise['exerciseValue']=localStorage.getItem('exerciseValue')
   this.exerciseOptionSelected=localStorage.getItem('exerciseAnswer')
   this.dataservice.exercisedata=this.exercise
   if(localStorage.getItem('exerciseAnswer')=="Never" || localStorage.getItem('exerciseAnswer')=="Rarely" || localStorage.getItem('exerciseAnswer')=="Sometimes" ||localStorage.getItem('exerciseAnswer')=="Always")
   {
     this.exerciseSelectedNext=true
   }
   else{
     this.exerciseSelectedNext=false
   }

  }
  exerciseOption1()
  {
    localStorage.setItem('exerciseQustionId', "10")
    localStorage.setItem('exerciseQuestion', "Do you exercise a minimum of 90 minutes per week?")
    localStorage.setItem('exerciseAnswer', "Never")
    localStorage.setItem('exerciseValue', "2")
    this.exerciseOptionSelected="Never"    
    this.exerciseSelectedNext=true;
  }
  exerciseOption2()
  {
    localStorage.setItem('exerciseQustionId', "10")
    localStorage.setItem('exerciseQuestion', "Do you exercise a minimum of 90 minutes per week?")
    localStorage.setItem('exerciseAnswer', "Rarely")
    localStorage.setItem('exerciseValue', "0")
    this.exerciseOptionSelected="Rarely"    
    this.exerciseSelectedNext=true;
  }
  exerciseOption3()
  {
    localStorage.setItem('exerciseQustionId', "10")
    localStorage.setItem('exerciseQuestion', "Do you exercise a minimum of 90 minutes per week?")
    localStorage.setItem('exerciseAnswer', "Sometimes")
    localStorage.setItem('exerciseValue', "-1")
    this.exerciseOptionSelected="Sometimes"    
    this.exerciseSelectedNext=true;
  }
  exerciseOption4()
  {
    localStorage.setItem('exerciseQustionId', "10")
    localStorage.setItem('exerciseQuestion', "Do you exercise a minimum of 90 minutes per week?")
    localStorage.setItem('exerciseAnswer', "Always")
    localStorage.setItem('exerciseValue', "-1")
    this.exerciseOptionSelected="Always"    
    this.exerciseSelectedNext=true;
  }
  exerciseBack()
  {
this.route.navigate(['assessment/9'])
  }
  exerciseNext()
  {
    this.route.navigate(['assessment/11'])
    this.exercise={}
    this.exercise['questionid']=localStorage.getItem('exerciseQustionId')
    this.exercise['question']=localStorage.getItem('exerciseQuestion')
   this.exercise['answer']=localStorage.getItem('exerciseAnswer')
   this.exerciseOptionSelected=localStorage.getItem('exerciseAnswer')
   this.dataservice.exercisedata=this.exercise

  }
}
