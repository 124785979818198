<head>
    <title>Artistry</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>

<body>

    <nav class="navbar">
        <div class="container">
            <a href="home"><img src="../../assets/images/artistry_logo_header.png" height="45px"/></a>
        </div>
    </nav>

    <div class="container page-content">
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div *ngIf="skinFeelAssessment">
                    <div class="question-section">
                        <h3>How does your skin feel in the middle of the day?</h3>
                    </div>

                    <div class="answer-section">
                        <div class="radio-img-design row flex-switch ">
                            
                            <div class="col-md-6">
                                <div class="radio">
                                    <input type="radio" id="optionsRadios1"
                                        value="My whole face feels dry and uncomfortable" name="skinFeel"
                                        [(ngModel)]="skinFeelValue">
                                    <label for="optionsRadios1" (click)="skinFeelOption1()">
                                        My whole face feels dry and uncomfortable.
                                    </label>
                                </div>
                                <div class="radio">
                                    <input type="radio" id="optionsRadios2" value="My whole face feels comfortable"
                                        name="skinFeel" [(ngModel)]="skinFeelValue">
                                    <label for="optionsRadios2" (click)="skinFeelOption2()">
                                        My whole face feels comfortable.
                                    </label>
                                </div>
                                <div class="radio">
                                    <input type="radio" id="optionsRadios3" name="skinFeel"
                                        value="My cheeks feel dry while my forehead/nose/chin feel oily"
                                        [(ngModel)]="skinFeelValue">
                                    <label for="optionsRadios3" (click)="skinFeelOption3()">
                                        My cheeks feel dry while my forehead/nose/chin feel oily.
                                    </label>
                                </div>
                                <div class="radio">
                                    <input type="radio" id="optionsRadios4" name="skinFeel"
                                        value="My whole face feels oily and looks shiny" [(ngModel)]="skinFeelValue">
                                    <label for="optionsRadios4" (click)="skinFeelOption4()">
                                        My whole face feels oily and looks shiny.
                                    </label>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="col-md-12" *ngIf="skinNoOption">
                                    <img src="../../assets/images/nooption.png" class="img-responsive" />
                                </div>
                                <div class="col-md-12" *ngIf="skinOily">
                                    <img src="../../assets/images/skintype-oily.png" class="img-responsive" />
                                </div>
                                <div class="col-md-12" *ngIf="skinNormal">
                                    <img src="../../assets/images/skintype-normal.png" class="img-responsive" />
                                </div>
                                <div class="col-md-12" *ngIf="skinDry">
                                    <img src="../../assets/images/skintype-dry.png" class="img-responsive" />
                                </div>
                                <div class="col-md-12" *ngIf="skinCombo">
                                    <img src="../../assets/images/skintype-combo.png" class="img-responsive" />
                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="next-back">
                        <button class="float-left back" *ngIf="skinFeelSelectedBack"
                            (click)="skinFeelBack()">Back</button>
                        <button class="float-right next" *ngIf="skinFeelSelectedNext"
                            (click)="skinFeelNext()">Next</button>
                    </div>



                </div>
            </div>
            <div class="col-sm-12 col-md-4">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="28" aria-valuemin="0"
                        aria-valuemax="100" style="width:28%">
                        <span>28%</span>
                    </div>
                </div>
            </div>
        </div>

    </div>

    <footer class="container-fluid text-center">
        <div class="col-sm-7 p-20-0">
            <p>
                Amway India
            </p>
            <p>
                Copyright © 2018 Amway India. All rights are Reserved
            </p>
        </div>
        <div class="col-sm-5"><img src="../../assets/images/Art_Log_3D_05C_sRGB_white.png" height="65px"
                class="img-responsive m-a" /></div>
    </footer>


</body>