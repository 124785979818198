import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-skincare',
  templateUrl: './skincare.component.html',
  styleUrls: ['./skincare.component.scss']
})
export class SkincareComponent implements OnInit {

  skinCareAssessment:boolean=false;
  skinCareSelected:boolean=true;
  skinCareSelectedNext:boolean=false;
  skinCareSelectedBack: boolean=true;
  skinCareOptionSelected: any;
  skinCare: {};
  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    this.skinCare={}
    this.skinCare['questionid']=localStorage.getItem('skinCareQustionId')
    this.skinCare['question']=localStorage.getItem('skinCareQuestion')
   this.skinCare['answer']=localStorage.getItem('skinCareAnswer')
   this.skinCare['skinCareValue']=localStorage.getItem('skinCareValue')
   this.skinCareOptionSelected=localStorage.getItem('skinCareAnswer')
   this.dataservice.skincaredata=this.skinCare
   if(localStorage.getItem('skinCareAnswer')=="Never" || localStorage.getItem('skinCareAnswer')=="Rarely" || localStorage.getItem('skinCareAnswer')=="Sometimes" ||localStorage.getItem('skinCareAnswer')=="Always")
   {
     this.skinCareSelectedNext=true
   }
   else{
     this.skinCareSelectedNext=false
   }
   
  }

  skinCareOption1()
  {
    localStorage.setItem('skinCareQustionId', "6")
    localStorage.setItem('skinCareQuestion', "Do you follow a daily skincare routine?")
    localStorage.setItem('skinCareAnswer', "Never")
    localStorage.setItem('skinCareValue', '1')
    this.skinCareOptionSelected="Never"    
    this.skinCareSelectedNext=true;
  }
  skinCareOption2()
  {
    localStorage.setItem('skinCareQustionId', "6")
    localStorage.setItem('skinCareQuestion', "Do you follow a daily skincare routine?")
    localStorage.setItem('skinCareAnswer', "Rarely")
    localStorage.setItem('skinCareValue', '1')
    this.skinCareOptionSelected="Rarely"    
    this.skinCareSelectedNext=true;

  }
  skinCareOption3()
  {
    localStorage.setItem('skinCareQustionId', "6")
    localStorage.setItem('skinCareQuestion', "Do you follow a daily skincare routine?")
    localStorage.setItem('skinCareAnswer', "Sometimes")
    localStorage.setItem('skinCareValue', '0')
    this.skinCareOptionSelected="Sometimes"    
    this.skinCareSelectedNext=true;
  }
  skinCareOption4()
  {
    localStorage.setItem('skinCareQustionId', "6")
    localStorage.setItem('skinCareQuestion', "Do you follow a daily skincare routine?")
    localStorage.setItem('skinCareAnswer', "Always")
    localStorage.setItem('skinCareValue', '-1')
    this.skinCareOptionSelected="Always"    
    this.skinCareSelectedNext=true;

  }


  skinCareBack()
{
 this.route.navigate(['assessment/5'])
}
skinCareNext()
{
  this.route.navigate(['assessment/7'])
  this.skinCare={}
  this.skinCare['questionid']=localStorage.getItem('skinCareQustionId')
  this.skinCare['question']=localStorage.getItem('skinCareQuestion')
 this.skinCare['answer']=localStorage.getItem('skinCareAnswer')
 this.skinCareOptionSelected=localStorage.getItem('skinCareAnswer')
 this.dataservice.skincaredata=this.skinCare
}

}
