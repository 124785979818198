import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-sunexpose',
  templateUrl: './sunexpose.component.html',
  styleUrls: ['./sunexpose.component.scss']
})
export class SunexposeComponent implements OnInit {
  sunExposeAssessment:boolean=true;
  sunExposeSelected:boolean=true;
  sunExposeSelectedNext: boolean=false;
  sunExposeSelectedBack: boolean=true;
  sunExposeOptionSelected:any;
  sunExpose: {};

  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    this.sunExpose={}
    this.sunExpose['questionid']=localStorage.getItem('sunExposeQustionId')
    this.sunExpose['question']=localStorage.getItem('sunExposeQuestion')
   this.sunExpose['answer']=localStorage.getItem('sunExposeAnswer')
   this.sunExpose['sunExposeValue']=localStorage.getItem('sunExposeValue')
   this.sunExposeOptionSelected=localStorage.getItem('sunExposeAnswer')
   this.dataservice.sunexposedata=this.sunExpose
   if(localStorage.getItem('sunExposeAnswer')=="Never" || localStorage.getItem('sunExposeAnswer')=="Rarely" || localStorage.getItem('sunExposeAnswer')=="Sometimes" ||localStorage.getItem('sunExposeAnswer')=="Always")
   {
     this.sunExposeSelectedNext=true
   }
   else{
     this.sunExposeSelectedNext=false
   }

  }

  sunExposeOption1()
  {
    localStorage.setItem('sunExposeQustionId', "5")
    localStorage.setItem('sunExposeQuestion', "Do you spend more than one hour per day exposed to the sun?")
    localStorage.setItem('sunExposeAnswer', "Never")
    localStorage.setItem('sunExposeValue', "-2")
    this.sunExposeOptionSelected="Never"    
    this.sunExposeSelectedNext=true;
  }
  sunExposeOption2()
  {
    localStorage.setItem('sunExposeQustionId', "5")
    localStorage.setItem('sunExposeQuestion', "Do you spend more than one hour per day exposed to the sun?")
    localStorage.setItem('sunExposeAnswer', "Rarely")
    localStorage.setItem('sunExposeValue', "-1")
    this.sunExposeOptionSelected="Rarely"    
    this.sunExposeSelectedNext=true;
  }
  sunExposeOption3()
  {
    localStorage.setItem('sunExposeQustionId', "5")
    localStorage.setItem('sunExposeQuestion', "Do you spend more than one hour per day exposed to the sun?")
    localStorage.setItem('sunExposeAnswer', "Sometimes")
    localStorage.setItem('sunExposeValue', "1")
    this.sunExposeOptionSelected="Sometimes"    
    this.sunExposeSelectedNext=true;
  }

  sunExposeOption4()
  {
    localStorage.setItem('sunExposeQustionId', "5")
    localStorage.setItem('sunExposeQuestion', "Do you spend more than one hour per day exposed to the sun?")
    localStorage.setItem('sunExposeAnswer', "Always")
    localStorage.setItem('sunExposeValue', "2")
    this.sunExposeOptionSelected="Always"    
    this.sunExposeSelectedNext=true;
  }
  sunExposeBack()
{
 
  this.route.navigate(['assessment/4'])
  


}
sunExposeNext()
{
this.route.navigate(['assessment/6'])
this.sunExpose={}
    this.sunExpose['questionid']=localStorage.getItem('sunExposeQustionId')
    this.sunExpose['question']=localStorage.getItem('sunExposeQuestion')
   this.sunExpose['answer']=localStorage.getItem('sunExposeAnswer')
   this.sunExposeOptionSelected=localStorage.getItem('sunExposeAnswer')
   this.dataservice.sunexposedata=this.sunExpose
}

}
