import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import * as apiport from '../../assets/apiport.json';
//import imageToBase64 from 'image-to-base64/browser'


import "../../assets/smtp.js";
import * as $ from 'jquery'
import { RestApiService } from '../realage/rest-api.service.js';
import { DomSanitizer } from '@angular/platform-browser';



@Component({
  selector: 'app-recommendations',
  templateUrl: './recommendations.component.html',
  styleUrls: ['./recommendations.component.scss']
})
export class RecommendationsComponent implements OnInit {
  userForm: FormGroup;
  emailPattern = "^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$";
  skinAge: string;
  currentAge: string;
  skinFeel: string;
  skinCondition: string;
  printStatus: boolean = true;
  additionalCheck: boolean = false;
  
  ID104129: boolean = false;
  ID104130: boolean = false;
  ID104126: boolean = false;
  ID104127: boolean = false;
  ID117654: boolean = false;
  ID107998: boolean = false;
  ID116409: boolean = false;
  ID116413: boolean = false;
  ID109709: boolean = false;
  ID104131: boolean = false;
  ID104128: boolean = false;
  ID113808: boolean = false;
  ID113810: boolean = false;
  ID113804: boolean = false;
  ID106401: boolean = false;
  ID121547: boolean = false;
  ID117655: boolean = false;
  ID117014: boolean = false;
  ID113809: boolean = false;
  ID113807: boolean = false;
  ID117016: boolean = false;
  ID117018: boolean = false;
  ID113803: boolean = false;
  ID121548: boolean = false;
  ID120524: boolean = false;
  ID121555: boolean = false;
  ID121556: boolean = false;
  ID121557: boolean = false;
  ID121558: boolean = false;
  ID121559: boolean = false;
  ID121560: boolean = false;


  prepareProductsCondition: boolean = false;
  infuseProductsCondition: boolean = false;
  nourishProductsCondition: boolean = false;
  
  prepareProducts = ["ID104129", "ID104130", "ID104126", "ID104127", "ID121547"]
  infuseProducts = ["ID116409", "ID109709", "ID117014", "ID113809","ID120524","ID121555","ID121556","ID121557","ID121558","ID121559","ID121560"]
  nourishProducts = ["ID104131", "ID104128", "ID113808", "ID113810", "ID113804", "ID113807", "ID117016", "ID117018", "ID113803", "ID121548"]
  
  additionalCheckPrepare: boolean = false;
  additoinalCheckInfuse: boolean = false;
 
  additionalProductsPrepare = ["ID117654", "ID106401", "ID117655", "ID107998"]
  additionalProductsInfuse = ["ID116413"]
  
  emailStatus: boolean = false;
  message: any;
  emailservice: boolean = false;
  emails = [];
  subjects: any;
  removeEmailId: boolean = false;
  addEmailId: boolean = false;
  base64104129ID: string;


  constructor(private fb: FormBuilder, private restapi: RestApiService,private sanitizer: DomSanitizer) {
    this.userForm = this.fb.group({
      message: new FormControl(),
      emails: this.fb.array([
        this.fb.control(null)
      ])
    })
  }

  ngOnInit() {
   

    
    // imageToBase64("../../assets/images/104129ID.png").then(
    //   (response)=>{
    //     this.base64104129ID="data:image/jpeg;base64,"+response
    //     console.log(this.base64104129ID)
    //   }
    // ).catch(
    //   (error)=>{
    //     console.log(error)
    //   }
    // )

    let skuIDs = []
    this.skinAge = localStorage.getItem('skinAge')
    // console.log(this.skinAge)
    this.currentAge = localStorage.getItem('difference')
    this.skinFeel = localStorage.getItem('skinFeel')
    this.skinCondition = localStorage.getItem('skinConditionAnswer')
    if (this.skinCondition == "Discoloration/Dark spots, Uneven Skintone and Loss of Radiance") {

      if ((Number(this.currentAge) > 17 && Number(this.currentAge) < 30)) {
        this.ID116413 = true;
        this.ID117654 = true;
        this.ID121548 = true;
        this.ID116409 = true;
        this.ID121560 = true;
        this.ID121557 = true;
        this.ID121555 = true;
        if (this.skinFeel == 'Normal' || this.skinFeel == 'Dry and Oily') {
          this.ID121547 = true;
          skuIDs.push("ID121547")
        }

        skuIDs.push("ID116413", "ID117654", "ID121548", "ID116409","ID121560", "ID121557","ID121555")
      } else if ((Number(this.currentAge) > 29 && Number(this.currentAge) < 40)) {
        
        if (this.skinFeel == "Dry" || this.skinFeel == "Normal") {
          this.ID113808 = true;
          this.ID113807 = true;
          skuIDs.push("ID113808", "ID113807")
        } else if (this.skinFeel == "Dry and Oily" || this.skinFeel == "Oily") {
          this.ID113803 = true;
          this.ID113804 = true;
          skuIDs.push("ID113803", "ID113803")
        }
        if (this.skinFeel == 'Normal' || this.skinFeel == 'Dry and Oily' || this.skinFeel == 'Dry') {
          this.ID121547 = true;
          skuIDs.push("ID121547")
        }
        this.ID121560 = true;
        this.ID116409 = true;
        this.ID121557 = true;
        this.ID113810 = true;
        this.ID116413 = true;
        this.ID117654 = true;
        this.ID121555 = true;
        skuIDs.push("ID116409", "ID113810", "ID116413", "ID117654","ID121560","ID121557","ID121555")
      } else if ((Number(this.currentAge) > 39)) {
        this.ID121560 = true;
        this.ID116409 = true;
        this.ID117016 = true;
        this.ID117018 = true;
        this.ID116413 = true;
        this.ID117654 = true;
        this.ID121557 = true;
        this.ID121555 = true;
        if (this.skinFeel == 'Normal' || this.skinFeel == 'Dry and Oily') {
          this.ID121547 = true;
          skuIDs.push("ID121547")
        }
        skuIDs.push("ID116409", "ID117016", "ID117018", "ID116413", "ID117654","ID121560","ID121557","ID121555")
      } else {

      }

    } else if (this.skinCondition == "Fine Lines and Wrinkles") {
      if ((Number(this.currentAge) > 17 && Number(this.currentAge) < 30)) {
        this.ID121547 = true;
        this.ID117654 = true;
        this.ID121548 = true;
        this.ID121558 = true;
        this.ID121555 = true;
        skuIDs.push("ID121547", "ID117654", "ID121548","ID121558","ID121555")
      } else if ((Number(this.currentAge) > 29 && Number(this.currentAge) < 40)) {
        if (this.skinFeel == "Dry" || this.skinFeel == "Normal") {
          this.ID113808 = true;
          this.ID113807 = true;
          skuIDs.push("ID113808", "ID113807")
        } else if (this.skinFeel == "Dry and Oily" || this.skinFeel == "Oily") {
          this.ID113803 = true;
          this.ID113804 = true;
          skuIDs.push("ID113803", "ID113804")
        }
        this.ID113809 = true;
        this.ID117654 = true;
        this.ID113810 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        this.ID121558 = true;
        this.ID121555 = true;
        skuIDs.push("ID113809", "ID117654", "ID113810", "ID121547","ID121558","ID121555")

      } else if ((Number(this.currentAge) > 39)) {
        this.ID107998 = true;
        this.ID117014 = true;
        this.ID117016 = true;
        this.ID117018 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        this.ID121558 = true;
        this.ID121555 = true;
        skuIDs.push("ID107998", "ID117014", "ID117016", "ID117018", "ID121547","ID121558","ID121555")

      } else {

      }
    }
    else if (this.skinCondition == "Dehydration and Dryness") {
      if ((Number(this.currentAge) > 17 && Number(this.currentAge) < 30)) {

        this.ID106401 = true;
        this.ID117654 = true;
        this.ID121548 = true;
        this.ID121556 = true;
        this.ID120524 = true;
        this.ID121555 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        if (this.skinFeel == 'Normal' || this.skinFeel == 'Dry and Oily' || this.skinFeel == 'Dry') {
          this.ID121547 = true;
          skuIDs.push("ID121547")
        }

        skuIDs.push("ID106401", "ID117654", "ID121548","ID121556","ID120524","ID121555")
      } else if ((Number(this.currentAge) > 29 && Number(this.currentAge) < 40)) {
        if (this.skinFeel == "Dry" || this.skinFeel == "Normal") {
          this.ID113808 = true;
          this.ID113807 = true;
          skuIDs.push("ID113808", "ID113807")
        } else if (this.skinFeel == "Dry and Oily" || this.skinFeel == "Oily") {
          this.ID113803 = true;
          this.ID113804 = true;
          skuIDs.push("ID113803", "ID113804")
        }
        this.ID113809 = true;
        this.ID106401 = true;
        this.ID117654 = true;
        this.ID117655 = true;
        this.ID113810 = true;
        this.ID121556 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        this.ID120524 = true;
        this.ID121555 = true;
        skuIDs.push("ID113809", "ID106401", "ID117654", "ID117655", "ID113810", "ID121547","ID121556","ID120524", "ID121555")
      } else if ((Number(this.currentAge) > 39)) {
        this.ID117654 = true;
        this.ID117655 = true;
        this.ID117014 = true;
        this.ID117016 = true;
        this.ID117018 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        this.ID121556 = true;
        this.ID120524 = true;
        this.ID121555 = true;
        skuIDs.push("ID117654", "ID117655", "ID117014", "ID117016", "ID117018", "ID121547","ID121556","ID120524","ID121555")
      } else {

      }
    }
    else if (this.skinCondition == "Uneven Texture") {
      if ((Number(this.currentAge) > 17 && Number(this.currentAge) < 30)) {
        this.ID117654 = true;
        this.ID121548 = true;
        this.ID116409 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        this.ID121555 = true;
        skuIDs.push("ID117654", "ID121548", "ID116409", "ID121547","ID121555")
      } else if ((Number(this.currentAge) > 29 && Number(this.currentAge) < 40)) {

        if (this.skinFeel == "Dry" || this.skinFeel == "Normal") {
          this.ID113808 = true;
          this.ID113807 = true;
          skuIDs.push("ID113808", "ID113807")
        } else if (this.skinFeel == "Dry and Oily" || this.skinFeel == "Oily") {
          this.ID113803 = true;
          this.ID113804 = true;
          skuIDs.push("ID113803", "ID113804")
        }
        this.ID116409 = true;
        this.ID117654 = true;
        this.ID113810 = true;

        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        this.ID121555 = true;
        skuIDs.push("ID116409", "ID117654", "ID113810", "ID121547", "ID121555")
      } else if ((Number(this.currentAge) > 39)) {
        this.ID116409 = true;
        this.ID107998 = true;

        this.ID117016 = true;
        this.ID117018 = true;

        this.ID121555 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        skuIDs.push("ID116409", "ID107998", "ID117016", "ID117018", "ID121547","ID121555")
      } else {

      }
    }
    else if (this.skinCondition == "Visible Pores") {
      if ((Number(this.currentAge) > 17 && Number(this.currentAge) < 30)) {
        this.ID117654 = true;
        this.ID121548 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        if (this.skinFeel == 'Normal' || this.skinFeel == 'Dry and Oily' || this.skinFeel == 'Dry') {
          this.ID121547 = true;
          skuIDs.push("ID121547")
        }

        skuIDs.push("ID117654", "ID121548")
      } else if ((Number(this.currentAge) > 29 && Number(this.currentAge) < 40)) {
        if (this.skinFeel == "Dry" || this.skinFeel == "Normal") {
          this.ID113808 = true;
          this.ID113807 = true;
          skuIDs.push("ID113808", "ID113807")
        } else if (this.skinFeel == "Dry and Oily" || this.skinFeel == "Oily") {
          this.ID113803 = true;
          this.ID113804 = true;
          skuIDs.push("ID113803", "ID113804")

        }
        this.ID113809 = true;
        this.ID117654 = true;
        this.ID113810 = true;

        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        skuIDs.push("ID113809", "ID117654", "ID113810", "ID121547")

      } else if ((Number(this.currentAge) > 39)) {
        this.ID117014 = true;
        this.ID107998 = true;

        this.ID117016 = true;
        this.ID117018 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        skuIDs.push("ID117014", "ID107998", "ID117016", "ID117018", "ID121547")
      } else {

      }
    }
    else if (this.skinCondition == "Loss of Elasticity and sagging") {
      if ((Number(this.currentAge) > 17 && Number(this.currentAge) < 30)) {
        this.ID121559 = true;
        this.ID117654 = true;
        this.ID121548 = true;
        this.ID106401 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        this.ID121555 = true;
        skuIDs.push("ID117654", "ID121548", "ID106401", "ID121547","ID121559","ID121555")

      } else if ((Number(this.currentAge) > 29 && Number(this.currentAge) < 40)) {
        if (this.skinFeel == "Dry" || this.skinFeel == "Normal") {
          this.ID113808 = true;
          this.ID113807 = true;
          skuIDs.push("ID113808", "ID113807")
        } else if (this.skinFeel == "Dry and Oily" || this.skinFeel == "Oily") {
          this.ID113803 = true;
          this.ID113804 = true;
          skuIDs.push("ID113803", "ID113804")

        }
        this.ID113809 = true;
        this.ID117654 = true;
        this.ID106401 = true;
        this.ID113810 = true;
        this.ID121559 = true;
        this.ID121555 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        if (this.skinFeel == 'Normal' || this.skinFeel == 'Dry and Oily' || this.skinFeel == 'Oily') {
          this.ID121547 = true;
          skuIDs.push("ID121547")
        }

        skuIDs.push("ID113809", "ID117654", "ID106401", "ID113810","ID121559","ID121555")

      } else if ((Number(this.currentAge) > 39)) {
        this.ID117016 = true;
        this.ID117018 = true;
        this.ID117654 = true;
        this.ID117014 = true;
        // 121547ID ---> Essentials by Artistry Gel Cleanser
        this.ID121547 = true;
        this.ID121559 = true;
        this.ID121555 = true;
        skuIDs.push("ID117016", "ID117018", "ID117654", "ID117014", "ID121547","ID121559","ID121555")

      } else {

      }
    }


    for (let a = 0; a < this.additionalProductsPrepare.length; a++) {
      for (let b = 0; b < skuIDs.length; b++) {
        if (this.additionalProductsPrepare[a] == skuIDs[b]) {
          this.additionalCheckPrepare = true;
          break;
        }
      }
    }

    for (let a = 0; a < this.additionalProductsInfuse.length; a++) {
      for (let b = 0; b < skuIDs.length; b++) {
        if (this.additionalProductsInfuse[a] == skuIDs[b]) {
          this.additoinalCheckInfuse = true;
          break;
        }
      }
    }
    for (let i = 0; i < this.prepareProducts.length; i++) {
      for (let z = 0; z < skuIDs.length; z++) {
        if (this.prepareProducts[i] == skuIDs[z]) {
          this.prepareProductsCondition = true;
          break;
        }
      }
    }
    for (let i = 0; i < this.infuseProducts.length; i++) {
      for (let z = 0; z < skuIDs.length; z++) {
        if (this.infuseProducts[i] == skuIDs[z]) {
          this.infuseProductsCondition = true;
          break;
        }
      }
    }
    for (let i = 0; i < this.nourishProducts.length; i++) {
      for (let z = 0; z < skuIDs.length; z++) {
        if (this.nourishProducts[i] == skuIDs[z]) {
          this.nourishProductsCondition = true;
          break;
        }
      }
    }


    $('div[data-target="#print"]').on('click', function () {
      $('#print').css({ 'display': 'block', 'opacity': '1' });
      $('#print .modal-dialog').css({ 'top': '50px', 'min-width': '700px' });
      $('body').css({ 'overflow': 'hidden' });
      $('.overlay').fadeIn('1000');
    })
    $('button[data-dismiss="modal"]').on('click', function () {
      $('#print').css({ 'display': 'none', 'opacity': '0' });
      $('body').css({ 'overflow': 'auto' });
      $('.overlay').fadeOut('1000');
    })

  }
 
  onPrint(divName) {

    const printContents = document.getElementById(divName).innerHTML;
    const originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;

    window.print();

    window.focus();
    $('#print').css({ 'display': 'none', 'opacity': '0' });
    $('body').css({ 'overflow': 'auto' });
    $('.overlay').fadeOut('1000');
    window.close()
    document.body.innerHTML = originalContents;
    location.reload()

  }

  addEmail(): void {
    (this.userForm.get('emails') as FormArray).push(
      this.fb.control(null)
    );
  }

  removeEmail(index) {

    (this.userForm.get('emails') as FormArray).removeAt(index);
  }

  getemailFormControls(): AbstractControl[] {
    return (<FormArray>this.userForm.get('emails')).controls
  }


  send(values) {

    this.emailservice = true;
    let body: any
    body = document.getElementById('emailting').outerHTML
    // console.log(body)

    // console.log(values)

    this.message = this.userForm.get('message').value
    this.emails = []


    this.emails = values.emails
    // console.log(this.emails)

    this.subjects = values.message
    // console.log(this.sanitizer.bypassSecurityTrustUrl(apiport.bypass))
    
    this.restapi.postEmail(this.emails, this.subjects, body).subscribe((data) => {
     
      if (data == 'email not sent') {

        this.emailStatus = false;
      
        this.userForm.reset()

      } else if (data == 'email sent') {

        this.emailStatus = true;
        this.removeEmailId = true
        this.addEmailId = true

        this.userForm.get('message').disable()
        this.userForm.get('emails').disable()
      }
    },(error)=>{ 
      this.sanitizer.bypassSecurityTrustUrl(apiport.bypass)
    
     
      // if(window.open("https://15.207.32.216:/api/email/send-email","_blank"))
      // {
      //   $.get("https://15.207.32.216:/api/email/send-email,")
      //   // this.sanitizer.bypassSecurityTrustUrl("https://15.207.32.216/api/email/send-email")
      //   // console.log("1")
      //   // this.sanitizer.bypassSecurityTrustHtml("https://15.207.32.216/api/email/send-email")
      //   // console.log("2")
      //   // this.sanitizer.bypassSecurityTrustResourceUrl("https://15.207.32.216/api/email/send-email")
      //   // console.log("3")
      //   // this.sanitizer.bypassSecurityTrustStyle("https://15.207.32.216/api/email/send-email")
      //   // console.log("4")
      //   // this.sanitizer.bypassSecurityTrustScript("https://15.207.32.216/api/email/send-email")
      //   // console.log("5")
      //  setTimeout(() => {
      //   let element1: HTMLElement = document.getElementById('details-button') as HTMLElement;
      //   element1.click()
      //   console.log("clicked")
        
      //  }, 1000); 
      // //  let element: HTMLElement = document.getElementsByClassName('secondary-button small-link')[0] as HTMLElement;
      // //   element.click()
      //   // let element1: HTMLElement = document.getElementById('details-button')[0] as HTMLElement;
      //   // element1.click()
      //   // let element2: HTMLElement = document.getElementsByClassName('small-link')[0] as HTMLElement;
      //   // element2.click()
      //   // let element3: HTMLElement = document.getElementById('proceed-link')[0] as HTMLElement;
      //   // element3.click()
      //   //return this.sanitizer.bypassSecurityTrustResourceUrl(apiport.bypass);
      // }
      // console.log(error)

    });
    
  }
  enableEmail() {
    this.removeEmailId = false
    this.addEmailId = false
    this.emailStatus = false
    this.userForm.reset()
    this.userForm.get('message').enable()
    this.userForm.get('emails').enable()
  }

}
