import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-antiskincare',
  templateUrl: './antiskincare.component.html',
  styleUrls: ['./antiskincare.component.scss']
})
export class AntiskincareComponent implements OnInit {
  antiSkinCareAssessment:boolean=false;
  antiSkinCareSelected:boolean=true;
  antiSkinCareSelectedBack:boolean=true;
  antiSkinCareSelectedNext: boolean=false;
  antiskinCare: {};
  antiskinCareOptionSelected:any;
  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    this.antiskinCare={}
    this.antiskinCare['questionid']=localStorage.getItem('antiskinCareQustionId')
    this.antiskinCare['question']=localStorage.getItem('antiskinCareQuestion')
   this.antiskinCare['answer']=localStorage.getItem('antiskinCareAnswer')
   this.antiskinCare['antiskinCareValue']=localStorage.getItem('antiskinCareValue')
   this.antiskinCareOptionSelected=localStorage.getItem('antiskinCareAnswer')
   this.dataservice.antiskincaredata=this.antiskinCare
   if(localStorage.getItem('antiskinCareAnswer')=="Never" || localStorage.getItem('antiskinCareAnswer')=="Rarely" || localStorage.getItem('antiskinCareAnswer')=="Sometimes" ||localStorage.getItem('antiskinCareAnswer')=="Always")
   {
     this.antiSkinCareSelectedNext=true
   }
   else{
     this.antiSkinCareSelectedNext=false
   }

  }

  antiskinCareOption1()
  {
    localStorage.setItem('antiskinCareQustionId', "7")
    localStorage.setItem('antiskinCareQuestion', "Do you use anti-aging skincare products?")
    localStorage.setItem('antiskinCareAnswer', "Never")
    localStorage.setItem('antiskinCareValue', "1")
    
    this.antiskinCareOptionSelected="Never"    
    this.antiSkinCareSelectedNext=true;
  }
  antiskinCareOption2()
  {
    localStorage.setItem('antiskinCareQustionId', "7")
    localStorage.setItem('antiskinCareQuestion', "Do you use anti-aging skincare products?")
    localStorage.setItem('antiskinCareAnswer', "Rarely")
    localStorage.setItem('antiskinCareValue', "0")
    this.antiskinCareOptionSelected="Rarely"    
    this.antiSkinCareSelectedNext=true;

  }
  antiskinCareOption3()
  {
    localStorage.setItem('antiskinCareQustionId', "7")
    localStorage.setItem('antiskinCareQuestion', "Do you use anti-aging skincare products?")
    localStorage.setItem('antiskinCareAnswer', "Sometimes")
    localStorage.setItem('antiskinCareValue', "-1")
    this.antiskinCareOptionSelected="Sometimes"    
    this.antiSkinCareSelectedNext=true;

  }
  antiskinCareOption4()
  {
    localStorage.setItem('antiskinCareQustionId', "7")
    localStorage.setItem('antiskinCareQuestion', "Do you use anti-aging skincare products?")
    localStorage.setItem('antiskinCareAnswer', "Always")
    localStorage.setItem('antiskinCareValue', "-1")
    this.antiskinCareOptionSelected="Always"    
    this.antiSkinCareSelectedNext=true;
  }

  antiSkinCareBack()
  {
    this.route.navigate(['assessment/6'])
  }
  
  antiSkinCareNext()
  {
  this.route.navigate(['assessment/8'])
  this.antiskinCare={}
    this.antiskinCare['questionid']=localStorage.getItem('antiskinCareQustionId')
    this.antiskinCare['question']=localStorage.getItem('antiskinCareQuestion')
   this.antiskinCare['answer']=localStorage.getItem('antiskinCareAnswer')
   this.antiskinCareOptionSelected=localStorage.getItem('antiskinCareAnswer')
   this.dataservice.antiskincaredata=this.antiskinCare
  }
}
