import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http'
@Injectable({
  providedIn: 'root'
})
export class dataService {
  age: any;//creating an object for the data
  skinconditiondata: any
  skinfeeldata: any
  sunprotectiondata: any
  sunexposedata: any
  skincaredata: any
  antiskincaredata: any
  stressdata: any
  sleepdata: any
  exercisedata: any;
  pollutiondata: any;
  waterdata: any;
  arrayofDate = []
  finalArrayData = []
  constructor(private http: HttpClient){}
  arrayofDatecomple() {
    this.arrayofDate = []

    this.arrayofDate.push(this.age)
    this.arrayofDate.push(this.skinconditiondata)
    this.arrayofDate.push(this.skinfeeldata)
    this.arrayofDate.push(this.sunprotectiondata)
    
    console.log(this.arrayofDate)
  }

  finalDataSurvey() {
    this.finalArrayData=[]
    let ageDate = {}
    ageDate['questionid'] = localStorage.getItem('birthQuestionId')
    ageDate['question'] = localStorage.getItem('birthQuestion')
    ageDate['anwser'] = localStorage.getItem('birthAnwser')
    ageDate['difference'] = localStorage.getItem('difference')
        this.finalArrayData.push(ageDate)
    
    let skincondition = {}
    skincondition["questionid"] = localStorage.getItem('skinConditionQuestionId')
    skincondition["question"] = localStorage.getItem('skinConditionQuestion')
    skincondition["answer"] = localStorage.getItem('skinConditionAnswer')
    this.finalArrayData.push(skincondition)
    
    let skinFeel = {}
    skinFeel['questionid'] = localStorage.getItem('skinFeelQustionId')
    skinFeel['question'] = localStorage.getItem('skinFeelQuestion')
    skinFeel['answer'] = localStorage.getItem('skinFeelAnswer')
    skinFeel['skinFeel']=localStorage.getItem('skinFeel')
    this.finalArrayData.push(skinFeel)

     let sunProtection={}
    sunProtection['questionid']=localStorage.getItem('sunProtectQustionId')
    sunProtection['question']=localStorage.getItem('sunProtectQuestion')
   sunProtection['answer']=localStorage.getItem('sunProtectAnswer')
   this.finalArrayData.push(sunProtection)

   let sunExpose={}
  sunExpose['questionid']=localStorage.getItem('sunExposeQustionId')
   sunExpose['question']=localStorage.getItem('sunExposeQuestion')
  sunExpose['answer']=localStorage.getItem('sunExposeAnswer')
  this.finalArrayData.push(sunExpose)

  let skinCare={}
  skinCare['questionid']=localStorage.getItem('skinCareQustionId')
  skinCare['question']=localStorage.getItem('skinCareQuestion')
 skinCare['answer']=localStorage.getItem('skinCareAnswer')
 this.finalArrayData.push(skinCare)

 let antiskinCare={}
    antiskinCare['questionid']=localStorage.getItem('antiskinCareQustionId')
    antiskinCare['question']=localStorage.getItem('antiskinCareQuestion')
   antiskinCare['answer']=localStorage.getItem('antiskinCareAnswer')
   this.finalArrayData.push(antiskinCare)

   let stress={}
    stress['questionid']=localStorage.getItem('stressQustionId')
    stress['question']=localStorage.getItem('stressQuestion')
   stress['answer']=localStorage.getItem('stressAnswer')
   this.finalArrayData.push(stress)

   let sleep={}
    sleep['questionid']=localStorage.getItem('sleepQustionId')
    sleep['question']=localStorage.getItem('sleepQuestion')
   sleep['answer']=localStorage.getItem('sleepAnswer')
   this.finalArrayData.push(sleep)

   let exercise={}
   exercise['questionid']=localStorage.getItem('exerciseQustionId')
   exercise['question']=localStorage.getItem('exerciseQuestion')
  exercise['answer']=localStorage.getItem('exerciseAnswer')
  this.finalArrayData.push(exercise)

   let pollution={}
    pollution['questionid']=localStorage.getItem('pollutionQustionId')
    pollution['question']=localStorage.getItem('pollutionQuestion')
   pollution['answer']=localStorage.getItem('pollutionAnswer')
   this.finalArrayData.push(pollution)

   let water={}
    water['questionid']=localStorage.getItem('waterQustionId')
    water['question']=localStorage.getItem('waterQuestion')
   water['answer']=localStorage.getItem('waterAnswer')
   this.finalArrayData.push(water)
   return this.finalArrayData
  }
  // httpOption = {
  //           headers: new HttpHeaders({
  //               'Content-Type': 'application/json'
  //           })
  //       }
  //       // public getUserSkinDetails(user): Observable<any> {
  //       //     console.log(JSON.stringify(user))
  //       //    // return this.http.post<any>(this.apiURL + 'assessment/13', JSON.stringify(user), this.httpOption) 
  //       // }
  //       public getUserSkinDetails(user){
  //           console.log(JSON.stringify(user))
  //           return this.http.get(JSON.stringify(user), this.httpOption)
  //       }


}