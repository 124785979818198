import { Inject, Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { EmptyError, Observable, pipe, throwError } from "rxjs";
import * as apiport from '../../assets/apiport.json';

@Injectable({
    providedIn: 'root'
  })

export class RestApiService {

   
  message="error"
      
     apiURL = apiport.port
   
    
    constructor(public http: HttpClient) {
      
     }
    
    
    
    
    httpOption = {
        headers: new HttpHeaders({
                    
            'Content-Type': 'application/json;charset=utf-8',
            'http-equiv': 'Content-Security-Policy',
            'content': 'upgrade-insecure-requests'
        }).append('Access-Control-Allow-Methods', 'GET, POST, DELETE, PUT').append('Access-Control-Allow-Origin', this.apiURL),
        withCredentials : false
    }
   

    public postEmail(email, subject, body): Observable<any> {
      
        var Data={'emails':email, 'subject':subject, 'body':body}
      
      return this.http.post(this.apiURL+ '/api/email/send-email', Data, this.httpOption)

    }
  

}

