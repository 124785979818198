<head>
  <title>Artistry</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>

</head>

<body>

  <nav class="navbar">
    <div class="container">
      <a href="home"><img src="../../assets/images/artistry_logo_header.png" height="45px"/></a>
    </div>
  </nav>

  <div class="container page-content">
    <div class="row">
      <div class="col-sm-12 col-md-7">
        &nbsp;
      </div>
      <div class="col-sm-12 col-md-5 p-t-15">

        <form [formGroup]="acceptanceForm" (ngSubmit)="acceptSubmit()">
          <img src="../../assets/images/logo-chrome.png" class="img-responsive" />
          <h4 class="home-para text-grey">FIND YOUR IDEAL SKINCARE ARTISTRY™ PRODUCTS WHICH ARE RIGHT FOR YOU</h4>
          <p class="text-grey">Artistry™ is constantly pushing the boundaries of beauty with the world's most
            scientifically advanced range of beauty solutions</p>
          <input type="checkbox" id="terms"  formControlName="acceptCheck" required> <label
            class="home-page-label text-grey" for="terms">I accept <a class="dark_link" href="/terms-of-use-and-privacy-policy">Terms & Conditions</a> and <a class="dark_link" href="/terms-of-use-and-privacy-policy">Privacy Policy</a></label>
          
          <button class="btn-primary btn d-block m-b-100" [disabled]="acceptanceForm.invalid"
            type="submit">START</button>
          
        </form>
      </div>
    </div>
  </div>




  <footer class="container-fluid text-center">
    <div class="col-sm-7 p-20-0">
      <p>
        Amway India
      </p>
      <p>
        Copyright © 2018 Amway India. All rights are Reserved
      </p>
    </div>
    <div class="col-sm-5"><img src="../../assets/images/Art_Log_3D_05C_sRGB_white.png" height="65px"
        class="img-responsive m-a" /></div>
  </footer>

</body>