<head>
    <title>Artistry</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>

<body>
    <nav class="navbar">
        <div class="container">
            <a href="home"><img src="../../assets/images/artistry_logo_header.png" height="45px"/></a>
        </div>
    </nav>
    <div class="container page-content">
        <div class="row">
            <div class="col-sm-12 col-md-8">

                <div *ngIf="skinConditionAssessment">
                    <div class="question-section">
                        <h3>Identify one main concern of your skin condition</h3>
                    </div>

                    <div class="answer-section">
                        <div class="click-design">
                            <div class="selected-area" *ngIf="skinConditionOpt!=null">
                                <ul>
                                    <li class="active">
                                        <button>{{skinConditionOpt}}</button>
                                        <button class="add" (click)="clearSkinCondition()"><span>+</span></button>
                                    </li>
                                </ul>
                            </div>
                            <div class="listing-area">
                                <ul>
                                    <li>
                                        <button>Discoloration/Dark spots, Uneven Skintone and Loss of Radiance</button>
                                        <button class="add" (click)="skinConditionOption1()">+</button>
                                    </li>
                                    <li>
                                        <button>Fine Lines and Wrinkles</button>
                                        <button class="add" (click)="skinConditionOption2()"><span>+</span></button>
                                    </li>
                                    <li>
                                        <button>Dehydration and Dryness</button>
                                        <button class="add" (click)="skinConditionOption3()"><span>+</span></button>
                                    </li>
                                    <li>
                                        <button>Uneven Texture</button>
                                        <button class="add" (click)="skinConditionOption4()"><span>+</span></button>
                                    </li>
                                    <li>
                                        <button>Visible Pores</button>
                                        <button class="add" (click)="skinConditionOption5()"><span>+</span></button>
                                    </li>
                                    <li>
                                        <button>Loss of Elasticity and sagging</button>
                                        <button class="add" (click)="skinConditionOption6()"><span>+</span></button>
                                    </li>
                                </ul>
                            </div>
                        </div>

                    </div>
                    <div class="next-back">
                        <button class="float-left back" *ngIf="skinConditionSelectedBack"
                            (click)="skinConditionBack()">Back</button>
                        <div *ngIf="skinConditionSelectedNext"><button class="float-right next"
                                (click)="skinConditionNext()">Next</button></div>

                    </div>
                </div>

            </div>
            <div class="col-sm-12 col-md-4">
                <div class="progress">
                    <div class="progress-bar" role="progressbar" aria-valuenow="21" aria-valuemin="0"
                        aria-valuemax="100" style="width:21%">
                        <span>21%</span>
                    </div>
                </div>
            </div>

        </div>
    </div>

    <footer class="container-fluid text-center">
        <div class="col-sm-7 p-20-0">
            <p>
                Amway India
            </p>
            <p>
                Copyright © 2018 Amway India. All rights are Reserved
            </p>
        </div>
        <div class="col-sm-5"><img src="../../assets/images/Art_Log_3D_05C_sRGB_white.png" height="65px"
                class="img-responsive m-a" /></div>
    </footer>
</body>