import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-sunprotection',
  templateUrl: './sunprotection.component.html',
  styleUrls: ['./sunprotection.component.scss']
})
export class SunprotectionComponent implements OnInit {
   sunProtectionAssessment: boolean=true;
   sunProtectionSelected:boolean= true;
   sunProtectionSelectedBack: boolean=true;
   sunProtectionSelectedNext: boolean=false;
   sunProtectionOptionSelected: string;
  sunProtection: {};

  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    this.sunProtection={}
    this.sunProtection['questionid']=localStorage.getItem('sunProtectQustionId')
    this.sunProtection['question']=localStorage.getItem('sunProtectQuestion')
   this.sunProtection['answer']=localStorage.getItem('sunProtectAnswer')
   this.sunProtection['sunProtectValue']=localStorage.getItem('sunProtectValue')
   this.sunProtectionOptionSelected=localStorage.getItem('sunProtectAnswer')
   this.dataservice.sunprotectiondata=this.sunProtection
   if(localStorage.getItem('sunProtectAnswer')=="Never" || localStorage.getItem('sunProtectAnswer')=="Rarely" || localStorage.getItem('sunProtectAnswer')=="Sometimes" ||localStorage.getItem('sunProtectAnswer')=="Always")
   {
     this.sunProtectionSelectedNext=true
   }
   else{
     this.sunProtectionSelectedNext=false
   }
   
  }
  sunProtectOption1()
  {
    localStorage.setItem('sunProtectQustionId', "4")
    localStorage.setItem('sunProtectQuestion', "How often do you wear sun protection?")
    localStorage.setItem('sunProtectAnswer', "Never")
    localStorage.setItem('sunProtectValue', "2")
    
    this.sunProtectionOptionSelected="Never"    
    this.sunProtectionSelectedNext=true;
    
  }
  sunProtectOption2()
  {
    localStorage.setItem('sunProtectQustionId', "4")
    localStorage.setItem('sunProtectQuestion', "How often do you wear sun protection?")
    localStorage.setItem('sunProtectAnswer', "Rarely")
    localStorage.setItem('sunProtectValue', "1")
    this.sunProtectionOptionSelected="Rarely"    
    this.sunProtectionSelectedNext=true;
    
  }
  sunProtectOption3()
  {
    localStorage.setItem('sunProtectQustionId', "4")
    localStorage.setItem('sunProtectQuestion', "How often do you wear sun protection?")
    localStorage.setItem('sunProtectAnswer', "Sometimes")
    localStorage.setItem('sunProtectValue', "0")
    this.sunProtectionOptionSelected="Sometimes"    
    this.sunProtectionSelectedNext=true;
    
  }
  sunProtectOption4()
  {
    localStorage.setItem('sunProtectQustionId', "4")
    localStorage.setItem('sunProtectQuestion', "How often do you wear sun protection?")
    localStorage.setItem('sunProtectAnswer', "Always")
    localStorage.setItem('sunProtectValue', "-2")
    this.sunProtectionOptionSelected="Always"    
    this.sunProtectionSelectedNext=true;

  }

  sunProtectionBack()
  {
    this.route.navigate(['assessment/3'])
  }
  sunProtectionNext()
  {
    this.route.navigate(['assessment/5'])
  }

}
