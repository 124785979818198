<head>
    <title>Artistry</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>

<body>
    <nav class="navbar">
        <div class="container">
            <a href="home"><img src="../../assets/images/artistry_logo_header.png" height="45px" /></a>
        </div>
    </nav>

    <div class="container page-content">
        <div class="row">
            <div class="col-sm-12 col-md-8">
                <div class="question-section bg-rosegold p-b-5">
                    <h2>These are the recommended skincare products, personalized for you</h2>
                    <div class="row m-t-5">
                        <div class="col-sm-6 col-md-2">
                            Age: {{currentAge}}
                        </div>
                        <div class="col-sm-6 col-md-4">
                            Skin Type: {{skinFeel}}
                        </div>
                        <div class="col-sm-12 col-md-6">
                            Primary Issue: {{skinCondition}}
                        </div>
                    </div>
                </div>
                <span *ngIf="prepareProductsCondition">
                    <div class="question-section bg-rosegold m-t-5">
                        <h2>PREPARE</h2>
                    </div>
                    <div class="question-section m-t-5">
                        <div class="row">
                            <div class="col-md-6" *ngIf="ID104129">
                                <div class="product-box">
                                    <img src={{base64104129ID}} class="img-responsive" />
                                    <p><strong>SKU#</strong> 104129ID</p>
                                    <h3 class="product-title">ARTISTRY™ Essentials Balancing Cleanser</h3>
                                    <p class="desc">Safe for use on sensitive skin. Best for combination-to-oily skin.
                                    </p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible1" class="toggle" type="checkbox">
                                        <label for="collapsible1" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    Cleanses, clarifies, and exfoliates skin to a balanced finish. It
                                                    contains patented exfoliation technology. Oil-free cleanser controls
                                                    shine all day. It adjusts for continuous oil control.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1399</strong><br />
                                            <strong>Net Qnty 135 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="http://www.amway.in/store/amway/en/INR/Open-Catalogue/Beauty/Skincare/Cleansers/ARTISTRY%E2%84%A2-Balancing-Cleanser/p/104129IDS"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID104130">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/104130ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 104130ID</p>
                                    <h3 class="product-title">ARTISTRY™ Essentials Balancing Toner</h3>
                                    <p class="desc">Energizes skin revealing a fresh, matte finish. Oil-free toner keeps
                                        skin perfectly balanced throughout the day.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible2" class="toggle" type="checkbox">
                                        <label for="collapsible2" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    Energizes skin revealing a fresh, matte finish. Oil-free toner keeps
                                                    skin perfectly balanced throughout the day.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1399</strong><br />
                                            <strong>Net Qnty 200 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="http://www.amway.in/store/amway/en/INR/Open-Catalogue/Beauty/Skincare/Toners/ARTISTRY%E2%84%A2-Balancing-Toner/p/104130IDS"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID104126">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/104126ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 104126ID</p>
                                    <h3 class="product-title">ARTISTRY™ Essentials Hydrating Cleanser</h3>
                                    <p class="desc">The first step in proper skin care. The cleanser that cleans and
                                        exfoliates. For normal-to-dry skin.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible3" class="toggle" type="checkbox">
                                        <label for="collapsible3" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    Hydrating cleanser cleanses, exfoliates and hydrates skin. This
                                                    creamy-rich, soap-free formula cleans effectively without leaving
                                                    oily
                                                    or drying residue. Skin feels soft, supple and completely refreshed.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1399</strong><br />
                                            <strong>Net Qnty 135 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="http://www.amway.in/store/amway/en/INR/Open-Catalogue/Beauty/Skincare/Cleansers/ARTISTRY%E2%84%A2-Hydrating-Cleanser/p/104126IDS"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID104127">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/104127ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 104127ID</p>
                                    <h3 class="product-title">ARTISTRY™ Essentials Hydrating Toner</h3>
                                    <p class="desc">Safe for use on sensitive skin. Best for normal-to-dry skin.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible4" class="toggle" type="checkbox">
                                        <label for="collapsible4" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    It provides essential moisturizers. Energizes skin, boosting its
                                                    ability
                                                    to stay hydrated throughout the day.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1399</strong><br />
                                            <strong>Net Qnty 200 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="http://www.amway.in/store/amway/en/INR/Open-Catalogue/Beauty/Skincare/Toners/ARTISTRY%E2%84%A2-Hydrating-Toner/p/104127IDS"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>



                            <div class="col-md-6" *ngIf="ID121547">

                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/121547ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 121547ID</p>
                                    <h3 class="product-title">Essentials by Artistry Gel Cleanser</h3>
                                    <p class="desc">Enriched with Cucumber, this Gel Cleanser is a sulfate-free,
                                        soap-free
                                        2-in-1 formula that effectively cleanses and tones skin in 1 step. This light
                                        foaming cleanser is a water based oil-free gel that deeply cleanses skin and
                                        removes
                                        make-up, impurities, clogging dirt and excess oil to help produce a fresher,
                                        cleaner-looking complexion.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible5" class="toggle" type="checkbox">
                                        <label for="collapsible5" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    In our attempt to adapt as per the changing skin care needs, we have
                                                    modified our Essentials range. The new and improved range is here to
                                                    cater to all skin types and that too within 3 minutes. This new
                                                    unisex
                                                    range is enriched with Botanical ingredients in every formula. These
                                                    ingredients add a special touch to each product in the range. The
                                                    hero
                                                    ingredient of this range is the Acerola Cherry Extract. It has been
                                                    specially acquired from our own Nutrilite farms and is present in
                                                    both
                                                    the daily care products – Gel Cleanser and Light Lotion. This 2-in-1
                                                    Gel
                                                    Cleanser-cum-Toner is enriched with Cucumber, which is well-known
                                                    for
                                                    its cooling and soothing effects. It provides a potent anti-oxidant
                                                    protection to the skin. It is also enriched with Acerola Cherry
                                                    which
                                                    acts as an anti-oxidant and is one of the richest known source of
                                                    Vitamin C.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1295</strong><br />
                                            <strong>Net Qnty 125 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/essentials-by-artistry-gel-cleanser/p/121547ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>
                </span>
                <span *ngIf="additionalCheckPrepare">
                    <div class="question-section bg-rosegold-1 m-t-5">
                        <h5>ADDITIONAL CARE</h5>
                        <div class="row">
                            <div class="col-md-6" *ngIf="ID117654">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/117654ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 117654ID</p>
                                    <h3 class="product-title">ARTISTRY™ Essentials Polishing Scrub</h3>
                                    <p class="desc">This gel based polishing scrub with mild & gentle exfoliating jojoba
                                        beads effectively removes flakes, impurities deep within the skin’s surface and
                                        excess oil, thereby revealing the skin’s radiance. As a result, the skin is left
                                        with a soft, smooth and polished glow.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible6" class="toggle" type="checkbox">
                                        <label for="collapsible6" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    In our attempt to adapt as per the changing skin care needs, we have
                                                    modified our Essentials range. The new and improved range is here to
                                                    cater to all skin types. While a daily skin care regime is extremely
                                                    important to keep one’s skin beautiful and glowing, it’s also
                                                    important
                                                    to pamper it every once in a while. The new Essentials range
                                                    consists
                                                    two such products that give your skin the much-needed rejuvenation:
                                                    Polishing Scrub that exfoliates the skin and Creamy Massage that
                                                    helps
                                                    restore radiance. This gel based polishing scrub with mild & gentle
                                                    exfoliating jojoba beads gently accelerate the skin’s natural
                                                    exfoliation process as the beads melt.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1499</strong><br />
                                            <strong>Net Qnty 125 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/essentials-by-artistry-polishing-scrub/p/117654ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID106401">

                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/106401ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 106401ID</p>
                                    <h3 class="product-title">ARTISTRY™ Essentials Moisture Intense Masque</h3>
                                    <p class="desc">Provides an immediate Moisture Surge.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible7" class="toggle" type="checkbox">
                                        <label for="collapsible7" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    Skin feels buttery soft just after one application of this light
                                                    whipped
                                                    soufflé. It restores your skin’s moisture barrier, keeping skin
                                                    hydrated
                                                    throughout the day & preventing future drying. Scented with a
                                                    soothing
                                                    cucumber-honeysuckle fragrance that relaxes & refreshes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1499</strong><br />
                                            <strong>Net Qnty 100 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-essentials-moisture-intense-masque/p/106401ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID117655">

                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/117655ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 117655ID</p>
                                    <h3 class="product-title">Essentials by Artistry Creamy Massage</h3>
                                    <p class="desc">Rich in Avocado, this luxurious massage cream restores radiance to
                                        the
                                        tired skin and makes it feel soft, silky, smooth and moisturized. It revitalizes
                                        the
                                        appearance of dry, dehydrated skin when applied with massage.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible8" class="toggle" type="checkbox">
                                        <label for="collapsible8" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    In our attempt to adapt as per the changing skin care needs, we have
                                                    modified our Essentials range. The new and improved range is here to
                                                    cater to all skin types. While a daily skin care regime is extremely
                                                    important to keep one’s skin beautiful and glowing, it’s also
                                                    important
                                                    to pamper it every once in a while. The new Essentials range
                                                    consists
                                                    two such products that give your skin the much-needed rejuvenation:
                                                    Polishing Scrub that exfoliates the skin and Creamy Massage that
                                                    helps
                                                    restore radiance. This luxurious massage cream is rich in Avocado
                                                    that
                                                    restores radiance to the tired skin and makes it feel soft, silky,
                                                    smooth and moisturized.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1649</strong><br />
                                            <strong>Net Qnty 100 g</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/essentials-by-artistry-creamy-massage/p/117655ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID107998">

                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/107998ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 107998ID</p>
                                    <h3 class="product-title">ARTISTRY INTENSIVE SKINCARE™ Renewing Peel</h3>
                                    <p class="desc">Rediscover youthful radiance and experience the benefits of a
                                        professional peel at home in just eight minutes with ARTISTRY® intensive
                                        skincare
                                        renewing peel. After just one use, 93% of women felt softer, smoother skin.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible9" class="toggle" type="checkbox">
                                        <label for="collapsible9" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    The science behind the smooth is our breakthrough mushroom enzyme.
                                                    Working in harmony with your skin, this powerful peel naturally
                                                    resurfaces without the need for harsh chemicals. Adapting to the
                                                    user’s
                                                    unique skin condition, exfoliation will cease when the pH reaches
                                                    5.5,
                                                    delivering optimal, professional-level benefits and a more radiant
                                                    complexion with every application. In one step, intensive skincare
                                                    renewing peel lets you experience the benefits of a professional
                                                    chemical peel at home without the risk or recovery time. After just
                                                    one
                                                    use, skin is smoother, softer, and radiance is restored. With
                                                    continued
                                                    use, over time fine lines and age spots begin to fade and skin
                                                    becomes
                                                    firmer with smaller-looking pores.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 4149</strong><br />
                                            <strong>Net Qnty 200 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-intensive-skin-care-renewing-peel/p/107998ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </span>
                <span *ngIf="infuseProductsCondition">
                    <div class="question-section bg-rosegold m-t-5">
                        <h2>INFUSE</h2>
                    </div>

                    <div class="question-section m-t-5">
                        <div class="row">
                            <div class="col-md-6" *ngIf="ID116409">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/116409ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 116409ID</p>
                                    <h3 class="product-title">ARTISTRY IDEAL RADIANCE™ Illuminating Essence</h3>
                                    <p class="desc">Introducing the ARTISTRY IDEAL RADIANCE™ Collection from Amway a
                                        brightening system harnessing an exclusive 3D Technology, addressing irritation,
                                        the
                                        appearance of hyper-pigmentation and environmental protection, via three layers
                                        of
                                        the skin’s surface, as means towards achieving unparalleled radiance.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible10" class="toggle" type="checkbox">
                                        <label for="collapsible10" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    Silky and fast absorbing, this luxurious milky essence delivers
                                                    unprecedented brightening power, giving skin an instant radiant
                                                    glow.
                                                    The appearance of dark spots and discoloration is significantly
                                                    reduced
                                                    leaving skin looking clearer, more even-toned and luminous.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 4949</strong><br />
                                            <strong>Net Qnty 35 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-ideal-radiance-illuminating-essence/p/116409ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID109709">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/109709ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 109709ID</p>
                                    <h3 class="product-title">ARTISTRY INTENSIVE SKINCARE™ Anti Wrinkle Firming Serum
                                    </h3>
                                    <p class="desc">ARTISTRY Intensive Skincare Anti-Wrinkle Firming Serum is targeting
                                        aging overnight. The technology in Artistry Anti-Wrinkle Firming Serum can be
                                        considered like a star with five points. Designed to address the breakdown of
                                        collagen and elastin, the Anti-Wrinkle Firming Serum formula contains five
                                        potent,
                                        key ingredients.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible11" class="toggle" type="checkbox">
                                        <label for="collapsible11" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    Benefits comparable to a professional treatment. Targeted products
                                                    to
                                                    fight the visible signs of aging. Advanced technology offering the
                                                    best
                                                    possible products. It is at-home solution to professional treatment
                                                    with
                                                    professional results. It uses a patented Targeting Complex to
                                                    reconstruct skin’s own firming mechanism as it smoothes lines and
                                                    wrinkles. Watch skin become more elastic, full and smooth again. See
                                                    resilience make a dramatic comeback in just 4 weeks.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 7600</strong><br />
                                            <strong>Net Qnty 30 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="http://www.amway.in/store/amway/en/INR/Open-Catalogue/Beauty/Skincare/Treatments/ARTISTRY%E2%84%A2-Anti-Wrinkle-Firming-Serum%2830ml%29/p/109709ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID117014">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/117014ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 117014ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Ultra Lifting Essence Concentrate
                                    </h3>
                                    <p class="desc">Daily use moisturizing serum to promote a more lifted and firmed
                                        look.
                                    </p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible12" class="toggle" type="checkbox">
                                        <label for="collapsible12" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    Daily use moisturizing serum to promote a more lifted and firmed
                                                    look.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 6599</strong><br />
                                            <strong>Net Qnty 30 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-youth-xtend-ultra-lifting-essence-concentrate/p/117014ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID113809">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/113809ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 113809ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Serum Concentrate</h3>
                                    <p class="desc">ARTISTRY YOUTH XTEND Serum Concentrate is formulated with rare and
                                        precious ingredients to help smooth the appearance of fine lines and wrinkles,
                                        improve overall radiance, and help keep skin looking younger, longer.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible13" class="toggle" type="checkbox">
                                        <label for="collapsible13" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    ARTISTRY YOUTH XTEND Serum Concentrate is the hero product of the
                                                    ARTISTRY YOUTH XTEND Collection. It contains the highest
                                                    concentration
                                                    of ALL the key ingredients from the ARTISTRY YOUTH XTEND Collection
                                                    –
                                                    LifeSirt, Micro-X6 Peptide, and African Baobab fruit extract. It
                                                    helps
                                                    smooth the appearance of fine lines and wrinkles, improves overall
                                                    radiance, and helps keep skin looking younger, longer.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 4789</strong><br />
                                            <strong>Net Qnty 30 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-youth-xtend-serum-concentrate/p/113809ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-6" *ngIf="ID120524">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/120524ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 120524ID</p>
                                    <h3 class="product-title">ARTISTRY™ Intensive Skincare Advanced Vitamin C + HA</h3>
                                    <p class="desc">Your 30-days power shot for a refreshed, plumped & youthful skin.
                                    </p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible14" class="toggle" type="checkbox">
                                        <label for="collapsible14" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    For extra care & attention to your skin amidst the increasing
                                                    pollution & stress. Inspired by professional dermal fillers*, this
                                                    product is enriched with unprecedented levels of 100% Vitamin C in
                                                    its innovating cap, along with Hyaluronic Acid.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 5829</strong><br />
                                            <strong>Net Qnty 12 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-intense-skincare-advanced-vitamin-c-ha/p/120524ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID121555">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/121555ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 121555ID</p>
                                    <h3 class="product-title">ARTISTRY SIGNATURE SELECT™ Base Serum</h3>
                                    <p class="desc">Enhances the Amplifiers' penetration by upto 175%* and supports
                                        their performance.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible15" class="toggle" type="checkbox">
                                        <label for="collapsible15" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    It supports the Amplifiers by establishing a healthy-looking,
                                                    nourished skin foundation, enhancing the Amplifiers' penetration by
                                                    upto 175%* and supporting their performance. To be mixed with
                                                    amplifiers.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 2999</strong><br />
                                            <strong>Net Qnty 24 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-signature-select-base-serum/p/121555ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID121556">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/121556ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 121556ID</p>
                                    <h3 class="product-title">ARTISTRY SIGNATURE SELECT™ Hydration Amplifier</h3>
                                    <p class="desc">PERSONALIZED CARE—JUST FOR YOU. Addresses unique skin concerns and
                                        targets what your skin needs the most - hydration.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible16" class="toggle" type="checkbox">
                                        <label for="collapsible16" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    For dehydrated & dry skin. Select up to 3 amplifiers and mix it with
                                                    the base serum to create your own personalized serum. Addresses your
                                                    unique skin concerns and targets what your skin needs the most.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 2599</strong><br />
                                            <strong>Net Qnty 2 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-signature-select-hydration-amplifier/p/121556ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID121557">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/121557ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 121557ID</p>
                                    <h3 class="product-title">ARTISTRY SIGNATURE SELECT™ Brightening Amplifier</h3>
                                    <p class="desc">PERSONALIZED CARE—JUST FOR YOU. Addresses unique skin concerns and
                                        targets what your skin needs the most - brightening.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible17" class="toggle" type="checkbox">
                                        <label for="collapsible17" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    ARTIFor dull and lackluster skin. Select up to 3 amplifiers and mix
                                                    it with the base serum to create your own personalized serum.
                                                    Addresses your unique skin concerns and targets what your skin needs
                                                    the most.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 2599</strong><br />
                                            <strong>Net Qnty 2 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-signature-select-brightening-amplifier/p/121557ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID121558">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/121558ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 121558ID</p>
                                    <h3 class="product-title">ARTISTRY SIGNATURE SELECT™ Anti-Wrinkle Amplifier</h3>
                                    <p class="desc">PERSONALIZED CARE—JUST FOR YOU. Addresses unique skin concerns and
                                        targets what your skin needs the most – anti wrinkle.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible18" class="toggle" type="checkbox">
                                        <label for="collapsible18" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    For fine lines and wrinkles. Select up to 3 amplifiers and mix it
                                                    with the base serum to create your own personalized serum. Addresses
                                                    your unique skin concerns and targets what your skin needs the most.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 2599</strong><br />
                                            <strong>Net Qnty 2 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-signature-select-anti-wrinkle-amplifier/p/121558ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID121559">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/121559ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 121559ID</p>
                                    <h3 class="product-title">ARTISTRY SIGNATURE SELECT™ Firming Amplifier</h3>
                                    <p class="desc">PERSONALIZED CARE—JUST FOR YOU. Addresses unique skin concerns and
                                        targets what your skin needs the most - firming.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible19" class="toggle" type="checkbox">
                                        <label for="collapsible19" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    For sagged, droopy skin. Select up to 3 amplifiers and mix it with
                                                    the base serum to create your own personalized serum. Addresses your
                                                    unique skin concerns and targets what your skin needs the most.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 3599</strong><br />
                                            <strong>Net Qnty 2 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-signature-select-firming-amplifier/p/121559ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID121560">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/121560ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 121560ID</p>
                                    <h3 class="product-title">ARTISTRY SIGNATURE SELECT™ Spot Corrector Amplifier</h3>
                                    <p class="desc">PERSONALIZED CARE—JUST FOR YOU. Addresses unique skin concerns and
                                        targets what your skin needs the most – spot corrector.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible20" class="toggle" type="checkbox">
                                        <label for="collapsible20" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    For dark spots, uneven skin tone. Select up to 3 amplifiers and mix
                                                    it with the base serum to create your own personalized serum.
                                                    Addresses your unique skin concerns and targets what your skin needs
                                                    the most.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 3399</strong><br />
                                            <strong>Net Qnty 2 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-signature-select-spot-corrector-amplifier/p/121560ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </span>
                <div class="question-section bg-rosegold-1 m-t-5" *ngIf="additoinalCheckInfuse">
                    <h5>ADDITIONAL CARE</h5>
                    <div class="row">
                        <div class="col-md-6" *ngIf="ID116413">
                            <div class="product-box">
                                <img src="https://artistryrecommender.amway.in/assets/images/116413ID.png" class="img-responsive" />
                                <p><strong>SKU#</strong> 116413ID</p>
                                <h3 class="product-title">ARTISTRY IDEAL RADIANCE™ Spot Corrector</h3>
                                <p class="desc">Introducing the ARTISTRY IDEAL RADIANCE™ Collection from Amway a
                                    brightening system harnessing an exclusive 3D Technology, addressing irritation, the
                                    appearance of hyper-pigmentation and environmental protection, via three layers of
                                    the skin’s surface, as means towards achieving unparalleled radiance.</p>
                                <div class="wrap-collabsible">
                                    <input id="collapsible14" class="toggle" type="checkbox">
                                    <label for="collapsible14" class="lbl-toggle">Description</label>
                                    <div class="collapsible-content">
                                        <div class="content-inner">
                                            <p>
                                                Helps target dark spots and discolorations. Helps diminish the
                                                appearance of most stubborn age spots. Leaves skin looking even-toned
                                                and radiant.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div class="bottom-strip">
                                    <div class="w50">
                                        <strong>*MRP ₹ 3149</strong><br />
                                        <strong>Net Qnty 15 gm</strong>
                                    </div>
                                    <div class="w50">
                                        <a href="https://www.amway.in/artistry-ideal-radiance-spot-corrector/p/116413ID"
                                            target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                src="../../assets/images/cart.svg" height="15px" /></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <span *ngIf="nourishProductsCondition">
                    <div class="question-section bg-rosegold m-t-5">
                        <h2>NOURISH</h2>
                    </div>


                    <div class="question-section m-t-5">
                        <div class="row">
                            <div class="col-md-6" *ngIf="ID104131">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/104131ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 104131ID</p>
                                    <h3 class="product-title">ARTISTRY™ Essentials Balancing Lotion</h3>
                                    <p class="desc">Safe for use on sensitive skin. Best for combination-to-oily skin.
                                    </p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible15" class="toggle" type="checkbox">
                                        <label for="collapsible15" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    It protects skin from the signs of premature aging. Features UVA/UVB
                                                    and
                                                    patent-pending free radical protection. Oil-free lotion keeps skin
                                                    perfectly balanced throughout the day.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1859</strong><br />
                                            <strong>Net Qnty 75 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="http://www.amway.in/store/amway/en/INR/Open-Catalogue/Beauty/Skincare/Moisturisers/ARTISTRY%E2%84%A2-Balancing-Lotion/p/104131IDS"
                                                class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID104128">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/104128ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 104128ID</p>
                                    <h3 class="product-title">ARTISTRY™ Essentials Hydrating Lotion</h3>
                                    <p class="desc">It protects skin from the signs of premature aging. Features UVA/UVB
                                        and
                                        patent-pending free radical protection. Keeps skin perfectly hydrated throughout
                                        the
                                        day.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible16" class="toggle" type="checkbox">
                                        <label for="collapsible16" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    It protects skin from the signs of premature aging. Features UVA/UVB
                                                    and
                                                    patent-pending free radical protection. Keeps skin perfectly
                                                    hydrated
                                                    throughout the day.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1859</strong><br />
                                            <strong>Net Qnty 75 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="http://www.amway.in/store/amway/en/INR/Open-Catalogue/Beauty/Skincare/Moisturisers/ARTISTRY%E2%84%A2-Hydrating-Lotion/p/104128IDS"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID113808">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/113808ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 113808ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Enriching Cream</h3>
                                    <p class="desc">A sumptuous moisturising night cream with ultra-hydrating
                                        ingredients to
                                        help smooth and revitalise skin for a softer, younger look overnight.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible17" class="toggle" type="checkbox">
                                        <label for="collapsible17" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    An integral part of the Power System#, which also includes Serum
                                                    Concentrate and Enriching Eye Cream. Works overnight to help
                                                    reprogram
                                                    the future of your skin. Helps repair existing damage, diminishing
                                                    the
                                                    look of fine lines and wrinkles while you sleep. 78% of women
                                                    reported
                                                    that the Power System#, including the Enriching Cream, helped to
                                                    reduce
                                                    the appearance of fine lines and wrinkles in just one week. Helps
                                                    improve moisture levels for smoother and softer skin. 89% of women
                                                    using
                                                    the Power System#, including Enriching Cream, noticed softer skin
                                                    after
                                                    one use. Ideal for normal-to-dry skin and dry skin.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 4239</strong><br />
                                            <strong>Net Qnty 50 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-youth-xtend-enriching-cream/p/113808ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px"></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID113810">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/113810ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 113810ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Enriching Eye Cream</h3>
                                    <p class="desc">The Youth Xtend Enriching Eye Cream is created specifically for the
                                        delicate eye area. The rich moisturizing eye cream helps diminish the look of
                                        fine
                                        lines and wrinkles for a younger, more vibrant look.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible18" class="toggle" type="checkbox">
                                        <label for="collapsible18" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    It targets the early signs of aging in the eye area. Helps diminish
                                                    the
                                                    look of fine lines and wrinkles. Powerful antioxidant complex helps
                                                    prevent the effects of environmental damage, leaving the skin around
                                                    the
                                                    eye area vibrant and refreshed. 93% of women experienced a clinical
                                                    reduction in visible fine lines around the eyes.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 2699</strong><br />
                                            <strong>Net Qnty 15 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a class="btn-primary btn buynow"
                                                href="https://www.amway.in/artistry-youth-xtend-enriching-eye-cream/p/113810ID"
                                                target="_blank">Buy Now <img src="../../assets/images/cart.svg"
                                                    height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID113804">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/113804ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 113804ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Enriching Lotion</h3>
                                    <p class="desc">A sumptuous, yet lightweight lotion that helps soften, smooth and
                                        hydrate your skin overnight.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible19" class="toggle" type="checkbox">
                                        <label for="collapsible19" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    A lightweight, non-greasy formula with special moisturising
                                                    ingredients
                                                    - meadowfoam seed oil and evening primrose oil. Specially formulated
                                                    for
                                                    normal-tocombination skin, Enriching Lotion provides long-lasting
                                                    moisturisation without leaving a greasy after-feel, while improving
                                                    smoothness, radiance, and clarity. LifeSirt, a botanical extract
                                                    from
                                                    the leaf of the Mediterranean myrtle plant to encourages skin's
                                                    natural
                                                    youth protein production by 280%,* strengthening and extending
                                                    skin's
                                                    healthy life to be more healthy looking.** The ARTISTRY exclusive
                                                    Micro-X6 Peptide to helps encourage collagen activity to aid in
                                                    repair
                                                    of existing skin damage.*** 78% of women reported that the Power
                                                    System#, including the Enriching Cream, helped to reduce the
                                                    appearance
                                                    of fine lines and wrinkles in just one week 89% of women using the
                                                    Power
                                                    System#, including Enriching Lotion, noticed softer skin in just one
                                                    use. *Based on in vitro gene expression assay **Based on in vitro
                                                    oxidative stress assay ***Based on in vitro assay # Power system
                                                    includes Serum concentrate, Enriching Eye Cream and Enriching
                                                    Lotion/Enriching Cream
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 4239</strong><br />
                                            <strong>Net Qnty 50 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-youth-xtend-enriching-lotion/p/113804ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID113807">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/113807ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 113807ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Protecting Cream</h3>
                                    <p class="desc">Advanced moisturising formula contains SPF 15, UVA/UVB and potent
                                        ingredients that provide comprehensive antioxidant protection to minimise and
                                        prevent the appearance of fine lines and wrinkles.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible20" class="toggle" type="checkbox">
                                        <label for="collapsible20" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    SPF 15 UVA/UVB PA+++, a broad-spectrum sunscreen with UV protection
                                                    to
                                                    help prevent the appearance of fine lines and wrinkles. Rich cream
                                                    with
                                                    highly moisturising ingredients to provides all-day moisturisation
                                                    for
                                                    more radiant-looking skin in only three weeks. A unique combination
                                                    of
                                                    conditioners, antioxidants, and soothing agents featuring African
                                                    Baobab
                                                    fruit extract plus black currant, green acerola cherry, and other
                                                    botanicals, some grown on our own organic farms. Skin is protected
                                                    against environmental aggressors such as free radicals and
                                                    pollution,
                                                    which can cause fine lines and wrinkles.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 4239</strong><br />
                                            <strong>Net Qnty 50 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-youth-xtend-protecting-cream/p/113807ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID117016">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/117016ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 117016ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Ultra Lifting Cream</h3>
                                    <p class="desc">Ultra-rich moisturizing cream for smoother, softer skin.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible21" class="toggle" type="checkbox">
                                        <label for="collapsible21" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    Ultra-rich moisturizing cream for smoother, softer skin. It contains
                                                    red
                                                    caviar concentrate which micro-cleanses the appearance of damage for
                                                    a
                                                    healthy-looking, rosy glow. The ultra-moisturizing ingredients add
                                                    intense moisturization and seal in skin's own moisture for a softer
                                                    and
                                                    smoother after-feel. Winter Cherry Ginseng helps clarify and
                                                    de-yellow
                                                    skin tone for healthy, younger-looking skin. Suitable for all skin
                                                    type.
                                                    Non comedogenic. ˜ARTISTRY YOUTH XTEND UTLRA Lifting Cream results
                                                    in
                                                    more radiant skin that feels softer and looks noticeably smoother
                                                    and
                                                    plumper, while skin's moisture content is improved overnight.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 6099</strong><br />
                                            <strong>Net Qnty 50 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-youth-xtend-ultra-lifting-cream/p/117016ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID117018">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/117018ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 117018ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Ultra Lifting Eye Serum concentrate
                                    </h3>
                                    <p class="desc">Daily use eye serum to be used underneath an eye cream. Provides
                                        lifting
                                        and firming benefits to the eye area.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible22" class="toggle" type="checkbox">
                                        <label for="collapsible22" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    It contains red caviar concentrate which helps rebuild the skin's
                                                    surface microstructure for a visibly firm and lifted look.
                                                    Micro-lifting
                                                    ingredients - Sunflower Seed Cake, Barley and Cucumber Extract
                                                    promotes
                                                    the formation of naturally occurring lipids in skin's deepest
                                                    surface
                                                    layers to help support and cushion skin's surface microstructure for
                                                    more defined facial contours. ARTISTRY Exclusive Micro-X6 Peptide
                                                    helps
                                                    reduce the appearance of fine lines and wrinkles. Fragrance-free.
                                                    Suitable for all skin types ˜With ARTISTRYTMYOUTH XTENDTMULTRA
                                                    Lifting
                                                    Eye Serum Concentrate, notice improved firmness and a brightened
                                                    look to
                                                    the eye area with ARTISTRY YOUTH XTEND ULTRA Lifting Eye Serum
                                                    Concentrate. Formulated specifically for the delicate skin of the
                                                    eye
                                                    area, skin appears lifted and firmer over time. The enriched formula
                                                    is
                                                    non-drying and hydrates on contact. ARTISTRY YOUTH XTEND UTLRA
                                                    Lifting
                                                    Cream results in more radiant skin that feels softer and looks
                                                    noticeably smoother and plumper, while skin's moisture content is
                                                    improved overnight.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 4200</strong><br />
                                            <strong>Net Qnty 15 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-youth-xtend-ultra-lifting-eye-serum-concentrate/p/117018V"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID113803">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/113803ID.png" class="img-responsive" />
                                    <p><strong>SKU#</strong> 113803ID</p>
                                    <h3 class="product-title">ARTISTRY YOUTH XTEND™ Protecting Lotion</h3>
                                    <p class="desc">Lightweight daytime lotion that prevents the appearance of fine
                                        lines
                                        and wrinkles, containing SPF 15, UVA/UVB and potent ingredients to provide
                                        comprehensive antioxidant protection.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible23" class="toggle" type="checkbox">
                                        <label for="collapsible23" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    SPF 15 UVA/UVB PA+++, a broad-spectrum sunscreen with UV protection
                                                    that
                                                    helps to prevent the appearance of fine lines and wrinkles.
                                                    Lightweight
                                                    lotion with highly moisturising ingredients to provide all-day
                                                    moisturisation for more radiant-looking skin in only three weeks. A
                                                    unique combination of conditioners, antioxidants, and soothing
                                                    agents
                                                    featuring African Baobab fruit extract plus black currant, green
                                                    acerola
                                                    cherry, and other botanicals, some grown on our own organic farms.
                                                    Skin
                                                    is protected against environmental aggressors such as free radicals
                                                    and
                                                    pollution, which can cause fine lines and wrinkles.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 4239</strong><br />
                                            <strong>Net Qnty 50 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a href="https://www.amway.in/artistry-youth-xtend-protecting-lotion/p/113803ID"
                                                target="_blank" class="btn-primary btn buynow">Buy Now <img
                                                    src="../../assets/images/cart.svg" height="15px" /></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" *ngIf="ID121548">
                                <div class="product-box">
                                    <img src="https://artistryrecommender.amway.in/assets/images/121548ID.jpg" class="img-responsive" />
                                    <p><strong>SKU#</strong> 121548ID</p>
                                    <h3 class="product-title">Essentials by Artistry Light Lotion</h3>
                                    <p class="desc">An oil-free lotion with Chamomile that hydrates the skin. It is
                                        enriched
                                        with ingredients that help reduce shine & surface oil. It is free of
                                        pore-clogging
                                        oils and absorbs into the skin quickly, leaving no residue.</p>
                                    <div class="wrap-collabsible">
                                        <input id="collapsible24" class="toggle" type="checkbox">
                                        <label for="collapsible24" class="lbl-toggle">Description</label>
                                        <div class="collapsible-content">
                                            <div class="content-inner">
                                                <p>
                                                    In our attempt to adapt as per the changing skin care needs, we have
                                                    modified our Essentials range. The new and improved range is here to
                                                    cater to all skin types and that too within 3 minutes. This new
                                                    unisex
                                                    range is enriched with Botanical ingredients in every formula. These
                                                    ingredients add a special touch to each product in the range. The
                                                    hero
                                                    ingredient of this range is the Acerola Cherry Extract. It has been
                                                    specially acquired from our own Nutrilite farms. It is present in
                                                    both
                                                    the daily care products – Gel Cleanser and Light Lotion. This
                                                    oil-free
                                                    lotion with Chamomile, which is well known for its soothing and
                                                    comforting effect, also provides anti-oxidant protection. When
                                                    partnered
                                                    with Acerola Cherry from the NUTRILITE™ brand, they together build
                                                    skin’s defense against free radicals and environmental assaults.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="bottom-strip">
                                        <div class="w50">
                                            <strong>*MRP ₹ 1239</strong><br />
                                            <strong>Net Qnty 50 ml</strong>
                                        </div>
                                        <div class="w50">
                                            <a class="btn-primary btn buynow"
                                                href="https://www.amway.in/essentials-by-artistry-light-lotion/p/121548ID"
                                                target="_blank">Buy Now <img src="../../assets/images/cart.svg"
                                                    height="15px" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    </div>
                </span>


            </div>
            <div class="col-sm-12 col-md-4">
                <div class="question-section bg-rosegold m-t-5">
                    <h2>Please use the products in the following order</h2>
                </div>
                <div class="question-section bg-rosegold-1 m-t-5">
                    <ol>
                        <li class="product-category" *ngIf="prepareProductsCondition || additionalCheckPrepare">
                            <span class="">PREPARE</span>
                            <ol class="">

                                <li class="product-title " *ngIf="ID104129">ARTISTRY™ Essentials Balancing Cleanser
                                </li>
                                <li class="product-title " *ngIf="ID104130">ARTISTRY™ Essentials Balancing Toner
                                </li>
                                <li class="product-title " *ngIf="ID104126">ARTISTRY™ Essentials Hydrating Cleanser
                                </li>
                                <li class="product-title " *ngIf="ID104127">ARTISTRY™ Essentials Hydrating Toner
                                </li>



                                <li class="product-title " *ngIf="ID121547">Essentials by Artistry Gel Cleanser
                                </li>


                            </ol>
                            <ol class="" *ngIf="additionalCheckPrepare">
                                <span class="product-cat-orderofuse">ADDITIONAL CARE</span>
                                <li class="product-title " *ngIf="ID117654">ARTISTRY™ Essentials Polishing Scrub
                                </li>
                                <li class="product-title " *ngIf="ID106401">ARTISTRY™ Essentials Moisture Intense Masque
                                </li>
                                <li class="product-title " *ngIf="ID117655">Essentials by Artistry Creamy Massage
                                </li>
                                <li class="product-title " *ngIf="ID107998">ARTISTRY INTENSIVE SKINCARE™ Renewing Peel
                                </li>
                            </ol>
                        </li>
                        <li class="product-category" *ngIf="infuseProductsCondition || additoinalCheckInfuse ">

                            <span class="">INFUSE</span>

                            <ol class="">

                                <li class="product-title " *ngIf="ID116409">ARTISTRY IDEAL RADIANCE™ Illuminating
                                    Essence
                                </li>

                                <li class="product-title " *ngIf="ID109709">ARTISTRY INTENSIVE SKINCARE™ Anti Wrinkle
                                    Firming Serum
                                </li>
                                <li class="product-title " *ngIf="ID117014">ARTISTRY YOUTH XTEND™ Ultra Lifting Essence
                                    Concentrate
                                </li>
                                <li class="product-title " *ngIf="ID113809">ARTISTRY YOUTH XTEND™ Serum Concentrate
                                </li>
                                <li class="product-title " *ngIf="ID120524">ARTISTRY™ Intensive Skincare Advanced
                                    Vitamin C + HA
                                </li>
                                <li class="product-title " *ngIf="ID121555">ARTISTRY SIGNATURE SELECT™ Base Serum
                                </li>
                                <li class="product-title " *ngIf="ID121556">ARTISTRY SIGNATURE SELECT™ Hydration
                                    Amplifier
                                </li>
                                <li class="product-title " *ngIf="ID121557">ARTISTRY SIGNATURE SELECT™ Brightening
                                    Amplifier
                                </li>
                                <li class="product-title " *ngIf="ID121558">ARTISTRY SIGNATURE SELECT™ Anti-Wrinkle
                                    Amplifier
                                </li>
                                <li class="product-title " *ngIf="ID121559">ARTISTRY SIGNATURE SELECT™ Firming Amplifier
                                </li>
                                <li class="product-title " *ngIf="ID121560">ARTISTRY SIGNATURE SELECT™ Spot Corrector
                                    Amplifier
                                </li>
                            </ol>
                            <ol class="" *ngIf="additoinalCheckInfuse">
                                <span class="product-cat-orderofuse">ADDITIONAL CARE</span>
                                <li class="product-title " *ngIf="ID116413">ARTISTRY IDEAL RADIANCE™ Spot Corrector
                                </li>

                            </ol>
                        </li>
                        <li class="product-category" *ngIf="nourishProductsCondition">

                            <span class="">NOURISH</span>
                            <ol class="">

                                <li class="product-title " *ngIf="ID104131">ARTISTRY™ Essentials Balancing Lotion
                                </li>
                                <li class="product-title " *ngIf="ID104128">ARTISTRY™ Essentials Hydrating Lotion
                                </li>
                                <li class="product-title " *ngIf="ID113808">ARTISTRY YOUTH XTEND™ Enriching Cream
                                </li>
                                <li class="product-title " *ngIf="ID113810">ARTISTRY YOUTH XTEND™ Enriching Eye Cream
                                </li>
                                <li class="product-title " *ngIf="ID113804">ARTISTRY YOUTH XTEND™ Enriching Lotion
                                </li>
                                <li class="product-title " *ngIf="ID113807">ARTISTRY YOUTH XTEND™ Protecting Cream
                                </li>
                                <li class="product-title " *ngIf="ID117016">ARTISTRY YOUTH XTEND™ Ultra Lifting Cream
                                </li>
                                <li class="product-title " *ngIf="ID117018">ARTISTRY YOUTH XTEND™ Ultra Lifting Eye
                                    Serum concentrate
                                </li>
                                <li class="product-title " *ngIf="ID113803">ARTISTRY YOUTH XTEND™ Protecting Lotion
                                </li>
                                <li class="product-title " *ngIf="ID121548">Essentials by Artistry Light Lotion
                                </li>
                            </ol>
                            <!-- <ol class="">
                                <span class="product-cat-orderofuse">ADDITIONAL CARE</span>
                                <li class="product-title ">ARTISTRY™ Essentials Polishing Scrub
                                </li>

                            </ol> -->
                        </li>
                    </ol>

                </div>
                <div class="question-section bg-rosegold m-t-5">
                    <h2>For more information, contact our customer care at 080 - 39416600</h2>
                </div>
                <div class="question-section bg-rosegold m-t-5">
                    <h2>Email</h2>
                </div>
                <form [formGroup]="userForm">
                    <div class="question-section bg-rosegold-1 m-t-5">

                        <div class="click-design">
                            <ul>
                                <div *ngFor="let email of getemailFormControls(); let i = index">
                                    <li>

                                        <input class="form-control" placeholder="Email Address" type="email"
                                            name="emails" id="emails" pattern="^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                                            [formControl]="email" required>


                                        <button *ngIf="i>0" (click)="removeEmail(i)" class="remove"
                                            [disabled]="removeEmailId">x</button>


                                        <button (click)="addEmail()" class="add" [disabled]="addEmailId">+</button>

                                    </li>
                                    <span *ngIf="email.invalid && (email.touched || email.dirty)">
                                        <span *ngIf="email.errors.required" style="color: red;">Email address is
                                            required</span>
                                        <span *ngIf="email.errors.pattern" style="color: red;"> Please enter Valid Email
                                            Address (Ex: abc@xzy.com)</span>
                                    </span>

                                    <span
                                        *ngIf="userForm.get('emails').invalid && (userForm.get('emails').touched || userForm.get('emails').dirty)"
                                        style="color: red;"> </span>

                                    <div style="padding-bottom: 5px;"></div>


                                </div>

                                <li>
                                    <textarea class="form-control" placeholder="Add a Message" rows="4"
                                        formControlName="message"></textarea>
                                </li>
                                <li *ngIf="emailStatus">
                                    <br>

                                    <p style="text-align: center;"> Email Sent Successfully </p>
                                    <button (click)="enableEmail()" [disabled]="userForm.invalid" class="submit"> DO YOU
                                        WANT TO SEND MORE?</button>
                                </li>
                                <li *ngIf="!emailStatus">

                                    <button (click)="send(userForm.value)" [disabled]="userForm.invalid" type="submit"
                                        class="submit"> Send</button>
                                </li>
                            </ul>
                        </div>
                    </div>
                </form>
                <div class="question-section bg-rosegold m-t-5 print" data-toggle="modal" data-target="#print">
                    <h2> Print <img src="../../assets/images/print-white.svg" height="20" /></h2>
                </div>
            </div>



        </div>
        <div class="row">
            <div class="col-sm-12 col-md-12 m-t-30 recommendations_footer">
                <p>* Inclusive of all taxes<br />Disclaimer : The product recommendations are purely based on the inputs
                    provided by the user and does not create any binding requirement to purchase the said products.</p>
            </div>
        </div>
    </div>

    <footer class="container-fluid text-center">
        <div class="col-sm-7 p-20-0">
            <p>
                Amway India
            </p>
            <p>
                Copyright © 2018 Amway India. All rights are Reserved
            </p>
        </div>
        <div class="col-sm-5"><img src="../../assets/images/Art_Log_3D_05C_sRGB_white.png" height="65px"
                class="img-responsive m-a" /></div>
    </footer>
    <div class="overlay"></div>
    <div id="print" class="modal fade" role="dialog">
        <div class="modal-dialog">
            <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <button type="button" class="close" data-dismiss="modal">close</button>
                    <h4 class="modal-title"> <input type="button" (click)="onPrint('printable')" class="btn"
                            style="background: rgba(163,143,109,0.4);" value="Print"> </h4>
                </div>
                <div class="modal-body" id="printable">
                    <div style="text-align:center;">
                        <img class="logo-recommender" src="../../assets/images/artistry_logo_header.png">
                    </div>
                    <table align="center" cellpadding="5" cellspacing="0" style="background:#BEB098" width="100%">
                        <tbody>
                            <tr class="print-border-bottom">
                                <td valign="top">
                                    <table align="center" border="0" cellpadding="0"
                                        style="font-size:14px;background:#BEB098;color:#fff;">
                                        <tbody>
                                            <tr>
                                                <td colspan="3" valign="top">
                                                    <h3
                                                        style="font-size:14px;padding-left:10px;padding-top:0px;margin-top:10px;margin-bottom:5px;">
                                                        <p>These are the recommended skincare products for you based on
                                                            your inputs</p>
                                                    </h3>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td width="20%" valign="top">
                                                    <div
                                                        style="padding-left:10px;padding-bottom:0px;padding-top:0px;margin-bottom:10px;">
                                                        <span class="sel-ans-label ng-binding">Age:
                                                            {{currentAge}}</span><span class="sel-ans-label"> Yrs</span>
                                                    </div>
                                                </td>
                                                <td valign="top" width="30%">
                                                    <div
                                                        style="padding-left:10px;padding-bottom:0px;padding-top:0px;margin-bottom:10px;">
                                                        <span class="sel-ans-label ng-binding">Skin Type:
                                                            {{skinFeel}}</span>
                                                    </div>
                                                </td>
                                                <td valign="top" width="50%">
                                                    <div
                                                        style="padding-left:10px;padding-bottom:0px;padding-top:0px;margin-bottom:10px;">
                                                        <span class="sel-ans-label ng-binding">Primary Issue:
                                                            {{skinCondition}}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <table align="center" border="0" cellpadding="5" cellspacing="0" width="650"
                        style="font-family:Helvetica,Arial,sans-serif; margin-top:15px " id="emailting">

                        <tbody>
                            <tr>
                                <td colspan="2" style="color:#000">
                                    <table align="left" border="0" cellpadding="5" cellspacing="0" width="650"
                                        style="font-family:Helvetica,Arial,sans-serif">
                                        <tbody>

                                            <tr>
                                                <td width="400">
                                                    <table align="left" cellpadding="0" cellspacing="0" width="390"
                                                        style="font-family:Helvetica,Arial,sans-serif">
                                                        <tbody>
                                                            <tr *ngIf="prepareProductsCondition">
                                                                <td colspan="2" style="border-bottom:1px solid #a89275">
                                                                    <table width="235" cellpadding="10">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td
                                                                                    style="background: rgba(163,143,109,0.4);padding:10px; text-transform: uppercase; color: #fff;">
                                                                                    Prepare</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID104129">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/104129ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top" style="">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Essentials Balancing Cleanser</h4>
                                                                    <p>Safe for use on sensitive skin. Best for
                                                                        combination-to-oily skin.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1399&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 135 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID104130">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/104130ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top" style="">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Essentials Balancing Toner</h4>
                                                                    <p>Energizes skin revealing a fresh, matte finish.
                                                                        Oil-free toner keeps
                                                                        skin perfectly balanced throughout the day.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1399&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty :200 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID104126">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img [src]="x"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top" >
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Essentials Hydrating Cleanser</h4>
                                                                    <p>The first step in proper skin care. The cleanser
                                                                        that cleans and
                                                                        exfoliates. For normal-to-dry skin.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1399&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty :135 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID104127">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/104127ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top" >
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Essentials Hydrating Toner</h4>
                                                                    <p>Safe for use on sensitive skin. Best for
                                                                        normal-to-dry skin.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1399&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 200 ml</span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID121547">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/121547ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top" >
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        Essentials by Artistry Gel Cleanser</h4>
                                                                    <p>Enriched with Cucumber, this Gel Cleanser is a
                                                                        sulfate-free,
                                                                        soap-free
                                                                        2-in-1 formula that effectively cleanses and
                                                                        tones skin in 1 step. This light
                                                                        foaming cleanser is a water based oil-free gel
                                                                        that deeply cleanses skin and
                                                                        removes
                                                                        make-up, impurities, clogging dirt and excess
                                                                        oil to help produce a fresher,
                                                                        cleaner-looking complexion.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1295&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 125 ml</span>
                                                                    </p>
                                                                </td>
                                                            </tr>

                                                            <tr *ngIf="additionalCheckPrepare">
                                                                <td colspan="2"
                                                                    style="border-bottom: 1px solid #a89275; padding:10px 0px; text-transform: uppercase;">
                                                                    Additional Product</td>
                                                            </tr>
                                                            <tr *ngIf="ID117654">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/117654ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Essentials Polishing Scrub</h4>
                                                                    <p>This gel based polishing scrub with mild & gentle
                                                                        exfoliating jojoba
                                                                        beads effectively removes flakes, impurities
                                                                        deep within the skin’s surface and
                                                                        excess oil, thereby revealing the skin’s
                                                                        radiance. As a result, the skin is left
                                                                        with a soft, smooth and polished glow.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1499&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 125 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID106401">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/106401ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Essentials Moisture Intense Masque
                                                                    </h4>
                                                                    <p>Skin feels buttery soft just after one
                                                                        application of this light
                                                                        whipped
                                                                        soufflé. It restores your skin’s moisture
                                                                        barrier, keeping skin
                                                                        hydrated
                                                                        throughout the day & preventing future drying.
                                                                        Scented with a
                                                                        soothing
                                                                        cucumber-honeysuckle fragrance that relaxes &
                                                                        refreshes.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1499&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 100 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID117655">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/117655ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        Essentials by Artistry Creamy Massage</h4>
                                                                    <p>Rich in Avocado, this luxurious massage cream
                                                                        restores radiance to
                                                                        the
                                                                        tired skin and makes it feel soft, silky, smooth
                                                                        and moisturized. It revitalizes
                                                                        the
                                                                        appearance of dry, dehydrated skin when applied
                                                                        with massage.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1649&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 100 g </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID107998">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/107998ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY INTENSIVE SKINCARE™ Renewing Peel</h4>
                                                                    <p>Rediscover youthful radiance and experience the
                                                                        benefits of a
                                                                        professional peel at home in just eight minutes
                                                                        with ARTISTRY® intensive
                                                                        skincare
                                                                        renewing peel. After just one use, 93% of women
                                                                        felt softer, smoother skin.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:4149&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 200 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="infuseProductsCondition">
                                                                <td colspan="2" style="border-bottom:1px solid #a89275">
                                                                    <table width="235" cellpadding="10">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td
                                                                                    style="background: rgba(163,143,109,0.4);padding:10px; text-transform: uppercase; color: #fff;">
                                                                                    Infuse</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID116409">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/116409ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY IDEAL RADIANCE™ Illuminating Essence
                                                                    </h4>
                                                                    <p>Introducing the ARTISTRY IDEAL RADIANCE™
                                                                        Collection from Amway a
                                                                        brightening system harnessing an exclusive 3D
                                                                        Technology, addressing irritation, the
                                                                        appearance of hyper-pigmentation and
                                                                        environmental protection, via three layers of
                                                                        the skin’s surface, as means towards achieving
                                                                        unparalleled radiance.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:4949&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 35 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID109709">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/109709ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY INTENSIVE SKINCARE™ Anti Wrinkle
                                                                        Firming Serum</h4>
                                                                    <p>ARTISTRY Intensive Skincare Anti-Wrinkle Firming
                                                                        Serum is targeting
                                                                        aging overnight. The technology in Artistry
                                                                        Anti-Wrinkle Firming Serum can be
                                                                        considered like a star with five points.
                                                                        Designed to address the breakdown of
                                                                        collagen and elastin, the Anti-Wrinkle Firming
                                                                        Serum formula contains five potent,
                                                                        key ingredients.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:7600&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 30 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID117014">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/117014ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Ultra Lifting Essence
                                                                        Concentrate</h4>
                                                                    <p>Daily use moisturizing serum to promote a more
                                                                        lifted and firmed look.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:6599&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 30 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID113809">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/113809ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Serum Concentrate</h4>
                                                                    <p>ARTISTRY YOUTH XTEND Serum Concentrate is
                                                                        formulated with rare and
                                                                        precious ingredients to help smooth the
                                                                        appearance of fine lines and wrinkles,
                                                                        improve overall radiance, and help keep skin
                                                                        looking younger, longer.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:4789&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 30 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID120524">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/120524ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Intensive Skincare Advanced Vitamin C
                                                                        + HA</h4>
                                                                    <p>Your 30-days power shot for a refreshed, plumped
                                                                        & youthful skin.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:5829&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 12 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID121555">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/121555ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY SIGNATURE SELECT™ Base Serum</h4>
                                                                    <p>Enhances the Amplifiers' penetration by upto
                                                                        175%* and supports their performance.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:2999&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 24 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID121556">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/121556ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY SIGNATURE SELECT™ Hydration Amplifier
                                                                    </h4>
                                                                    <p>PERSONALIZED CARE—JUST FOR YOU. Addresses unique
                                                                        skin concerns and targets what your skin needs
                                                                        the most – hydration.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:2599&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 2 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID121557">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/121557ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY SIGNATURE SELECT™ Brightening Amplifier
                                                                    </h4>
                                                                    <p>PERSONALIZED CARE—JUST FOR YOU. Addresses unique
                                                                        skin concerns and targets what your skin needs
                                                                        the most – brightening.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:2599&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 2 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID121558">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/121558ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY SIGNATURE SELECT™ Anti-Wrinkle
                                                                        Amplifier</h4>
                                                                    <p>PERSONALIZED CARE—JUST FOR YOU. Addresses unique
                                                                        skin concerns and targets what your skin needs
                                                                        the most – anti wrinkle.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:2599&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 2 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID121559">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/121559ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY SIGNATURE SELECT™ Firming Amplifier
                                                                    </h4>
                                                                    <p>PERSONALIZED CARE—JUST FOR YOU. Addresses unique
                                                                        skin concerns and targets what your skin needs
                                                                        the most – firming.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:3599&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 2 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID121560">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/121560ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY SIGNATURE SELECT™ Spot Corrector
                                                                        Amplifier</h4>
                                                                    <p>PERSONALIZED CARE—JUST FOR YOU. Addresses unique
                                                                        skin concerns and targets what your skin needs
                                                                        the most – spot corrector.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:3399&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 2 ml </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="additoinalCheckInfuse">
                                                                <td colspan="2"
                                                                    style="border-bottom: 1px solid #a89275; padding:10px 0px; text-transform: uppercase;">
                                                                    Additional Product</td>
                                                            </tr>
                                                            <tr *ngIf="ID116413">
                                                                <td valign="top" width="100">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/116413ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY IDEAL RADIANCE™ Spot Corrector</h4>
                                                                    <p>Introducing the ARTISTRY IDEAL RADIANCE™
                                                                        Collection from Amway a
                                                                        brightening system harnessing an exclusive 3D
                                                                        Technology, addressing irritation, the
                                                                        appearance of hyper-pigmentation and
                                                                        environmental protection, via three layers of
                                                                        the skin’s surface, as means towards achieving
                                                                        unparalleled radiance.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:3149&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 15 gm </span>
                                                                    </p>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="nourishProductsCondition">
                                                                <td colspan="2" style="border-bottom:1px solid #a89275">
                                                                    <table width="235" cellpadding="10">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td
                                                                                    style="background: rgba(163,143,109,0.4);padding:10px; text-transform: uppercase; color: #fff;">
                                                                                    Nourish</td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID104131">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/104131ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Essentials Balancing Lotion</h4>
                                                                    <p>Safe for use on sensitive skin. Best for
                                                                        combination-to-oily skin.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1859&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 75 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID104128">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/104128ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY™ Essentials Hydrating Lotion</h4>
                                                                    <p>It protects skin from the signs of premature
                                                                        aging. Features UVA/UVB and
                                                                        patent-pending free radical protection. Keeps
                                                                        skin perfectly hydrated throughout the
                                                                        day.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1859&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 75 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID113808">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/113808ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Enriching Cream</h4>
                                                                    <p>A sumptuous moisturising night cream with
                                                                        ultra-hydrating ingredients to
                                                                        help smooth and revitalise skin for a softer,
                                                                        younger look overnight.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:4239&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 50 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID113810">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/113810ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Enriching Eye Cream</h4>
                                                                    <p>The Youth Xtend Enriching Eye Cream is created
                                                                        specifically for the
                                                                        delicate eye area. The rich moisturizing eye
                                                                        cream helps diminish the look of fine
                                                                        lines and wrinkles for a younger, more vibrant
                                                                        look.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:2699&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 15 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID113804">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/113804ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Enriching Lotion</h4>
                                                                    <p>A sumptuous, yet lightweight lotion that helps
                                                                        soften, smooth and
                                                                        hydrate your skin overnight.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:4239&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 50 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID113807">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/113807ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                      
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Protecting Cream</h4>
                                                                    <p>Advanced moisturising formula contains SPF 15,
                                                                        UVA/UVB and potent
                                                                        ingredients that provide comprehensive
                                                                        antioxidant protection to minimise and
                                                                        prevent the appearance of fine lines and
                                                                        wrinkles.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:4239&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 50 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID117016">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/117016ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Ultra Lifting Cream</h4>
                                                                    <p>Ultra-rich moisturizing cream for smoother,
                                                                        softer skin.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:6099&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 50 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>

                                                            <tr *ngIf="ID117018">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/117018ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Ultra Lifting Eye Serum
                                                                        concentrate</h4>
                                                                    <p>Daily use eye serum to be used underneath an eye
                                                                        cream. Provides lifting
                                                                        and firming benefits to the eye area.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:4200&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 15 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID113803">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/113803ID.png"
                                                                                        style="WIDTH:100px" width="100"
                                                                                        
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        ARTISTRY YOUTH XTEND™ Protecting Lotion</h4>
                                                                    <p>Lightweight daytime lotion that prevents the
                                                                        appearance of fine lines
                                                                        and wrinkles, containing SPF 15, UVA/UVB and
                                                                        potent ingredients to provide
                                                                        comprehensive antioxidant protection.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:4239&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 50 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>
                                                            <tr *ngIf="ID121548">
                                                                <td valign="top" width="100"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <table width="100">
                                                                        <tbody>
                                                                            <tr>
                                                                                <td align="center"
                                                                                    style="text-align:center;vertical-align:middle">
                                                                                    <img src="https://artistryrecommender.amway.in/assets/images/121548ID.jpg"
                                                                                        style="WIDTH:100px" width="100"
                                                                                       
                                                                                        class="CToWUd">
                                                                                </td>
                                                                            </tr>
                                                                        </tbody>
                                                                    </table>
                                                                </td>
                                                                <td width="300" valign="top"
                                                                    style="border-bottom:1px solid #a89275">
                                                                    <h4
                                                                        style="text-transform:uppercase;font-size:12px;color:#a89275;font-weight:bold">
                                                                        Essentials by Artistry Light Lotion</h4>
                                                                    <p>An oil-free lotion with Chamomile that hydrates
                                                                        the skin. It is enriched
                                                                        with ingredients that help reduce shine &
                                                                        surface oil. It is free of pore-clogging
                                                                        oils and absorbs into the skin quickly, leaving
                                                                        no residue.</p>
                                                                    <p
                                                                        style="font-size:12px;font-weight:bold;color:#000">
                                                                        *MRP:1239&nbsp;
                                                                        <br><span
                                                                            style="font-size:12px;font-weight:bold;color:#000">Net
                                                                            Qnty : 50 ml </span>
                                                                    </p>

                                                                </td>
                                                            </tr>

                                                        </tbody>
                                                    </table>
                                                </td>
                                                <td valign="top" width="250">
                                                    <table align="left" cellpadding="5" cellspacing="0" width="250"
                                                        style="font-family:Helvetica,Arial,sans-serif">
                                                        <tbody>
                                                            <tr>
                                                                <td valign="top"
                                                                    style="background-color:#a89275;font-size:14px;color:#fff; padding: 1px 10px">
                                                                    Please use the products in the following order</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="220" style="word-wrap:break-word">
                                                                    <ol style="padding: 0px 0px 0px 15px; list-style: none;">
                                                                        <li style="font-size:12px"
                                                                            *ngIf="prepareProductsCondition || additionalCheckPrepare">
                                                                            <h3 style="font-size: 15px; text-transform: uppercase;">Prepare </h3>
                                                                            <ol >
                                                                                <li *ngIf="ID104129">ARTISTRY™
                                                                                    Essentials Balancing Cleanser
                                                                                </li>
                                                                                <li *ngIf="ID104130">ARTISTRY™
                                                                                    Essentials Balancing Toner
                                                                                </li>
                                                                                <li *ngIf="ID104126">ARTISTRY™
                                                                                    Essentials Hydrating Cleanser
                                                                                </li>
                                                                                <li *ngIf="ID104127">ARTISTRY™
                                                                                    Essentials Hydrating Toner
                                                                                </li>
                                                                                <li *ngIf="ID121547">Essentials by
                                                                                    Artistry Gel Cleanser
                                                                                </li>

                                                                            </ol>
                                                                            <h3 style="font-size: 15px; text-transform: uppercase;  margin-top: 10px; padding-left: 27px;" *ngIf="additionalCheckPrepare">Additional Product</h3>
                                                                            <ol *ngIf="additionalCheckPrepare">
                                                                               
                                                                                <li *ngIf="ID117654">ARTISTRY™
                                                                                    Essentials Polishing Scrub
                                                                                </li>
                                                                                <li *ngIf="ID106401">ARTISTRY™
                                                                                    Essentials Moisture Intense Masque
                                                                                </li>
                                                                                <li *ngIf="ID117655">Essentials by
                                                                                    Artistry Creamy Massage
                                                                                </li>
                                                                                <li *ngIf="ID107998">ARTISTRY INTENSIVE
                                                                                    SKINCARE™ Renewing Peel
                                                                                </li>
                                                                            </ol>
                                                                            <ol></ol>
                                                                        </li>
                                                                        <li style="font-size:14px"
                                                                            *ngIf="infuseProductsCondition ||additoinalCheckInfuse ">
                                                                            <h3 style="font-size: 15px; text-transform: uppercase;  margin-top: 10px;">Infuse</h3>
                                                                            <ol>
                                                                                <li *ngIf="ID116409">ARTISTRY IDEAL
                                                                                    RADIANCE™ Illuminating
                                                                                    Essence
                                                                                </li>

                                                                                <li *ngIf="ID109709">ARTISTRY INTENSIVE
                                                                                    SKINCARE™ Anti Wrinkle
                                                                                    Firming Serum
                                                                                </li>
                                                                                <li *ngIf="ID117014">ARTISTRY YOUTH
                                                                                    XTEND™ Ultra Lifting Essence
                                                                                    Concentrate
                                                                                </li>
                                                                                <li *ngIf="ID113809">ARTISTRY YOUTH
                                                                                    XTEND™ Serum Concentrate
                                                                                </li>
                                                                                <li *ngIf="ID120524">ARTISTRY™ Intensive
                                                                                    Skincare Advanced Vitamin C + HA
                                                                                </li>
                                                                                <li *ngIf="ID121555">ARTISTRY SIGNATURE
                                                                                    SELECT™ Base Serum
                                                                                </li>
                                                                                <li *ngIf="ID121556">ARTISTRY SIGNATURE
                                                                                    SELECT™ Hydration Amplifier
                                                                                </li>
                                                                                <li *ngIf="ID121557">ARTISTRY SIGNATURE
                                                                                    SELECT™ Brightening Amplifier
                                                                                </li>
                                                                                <li *ngIf="ID121558">ARTISTRY SIGNATURE
                                                                                    SELECT™ Anti-Wrinkle Amplifier
                                                                                </li>
                                                                                <li *ngIf="ID121559">ARTISTRY SIGNATURE
                                                                                    SELECT™ Firming Amplifier
                                                                                </li>
                                                                                <li *ngIf="ID121560">ARTISTRY SIGNATURE
                                                                                    SELECT™ Spot Corrector Amplifier
                                                                                </li>
                                                                            </ol>
                                                                            <h3 style="font-size: 15px; text-transform: uppercase;  margin-top: 10px; padding-left: 27px;" *ngIf="additoinalCheckInfuse"> Additional Product</h3>
                                                                            <ol *ngIf="additoinalCheckInfuse">
                                                                              
                                                                                <li *ngIf="ID116413">ARTISTRY IDEAL
                                                                                    RADIANCE™ Spot Corrector
                                                                                </li>
                                                                            </ol>
                                                                            <ol></ol>
                                                                        </li>
                                                                        <li style="font-size:14px"
                                                                            *ngIf="nourishProductsCondition">
                                                                            <h3 style="font-size: 15px; text-transform: uppercase; margin-top: 10px;">Nourish</h3>
                                                                            <ol>

                                                                                <li *ngIf="ID104131">ARTISTRY™
                                                                                    Essentials Balancing Lotion
                                                                                </li>
                                                                                <li *ngIf="ID104128">ARTISTRY™
                                                                                    Essentials Hydrating Lotion
                                                                                </li>
                                                                                <li *ngIf="ID113808">ARTISTRY YOUTH
                                                                                    XTEND™ Enriching Cream
                                                                                </li>
                                                                                <li *ngIf="ID113810">ARTISTRY YOUTH
                                                                                    XTEND™ Enriching Eye Cream
                                                                                </li>
                                                                                <li *ngIf="ID113804">ARTISTRY YOUTH
                                                                                    XTEND™ Enriching Lotion
                                                                                </li>
                                                                                <li *ngIf="ID113807">ARTISTRY YOUTH
                                                                                    XTEND™ Protecting Cream
                                                                                </li>
                                                                                <li *ngIf="ID117016">ARTISTRY YOUTH
                                                                                    XTEND™ Ultra Lifting Cream
                                                                                </li>
                                                                                <li *ngIf="ID117018">ARTISTRY YOUTH
                                                                                    XTEND™ Ultra Lifting Eye
                                                                                    Serum concentrate
                                                                                </li>
                                                                                <li *ngIf="ID113803">ARTISTRY YOUTH
                                                                                    XTEND™ Protecting Lotion
                                                                                </li>
                                                                                <li *ngIf="ID121548">Essentials by
                                                                                    Artistry Light Lotion
                                                                                </li>

                                                                            </ol>

                                                                        </li>
                                                                    </ol>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                            <tr>
                                <td colspan="2" height="20">

                                </td>
                            </tr>
                            <tr style="background: rgba(163,143,109,0.4);">
                                <td valign="top" width="50%" style="padding:10px; color: #fff;">
                                    <p style="line-height:100%">Learn more about how to improve your beauty to next
                                        level.</p>
                                    <p style="line-height:100%">Contact us today or visit&nbsp;<a
                                            href="http://www.amway.ru/" target="_blank"
                                            style="color: #a89275">www.amway.in</a>
                                    </p>
                                </td>
                                <td valign="top" width="50%" style="padding:10px; color: #fff;">
                                    <p style="line-height:100%">If you would like to speak with a Customer Service
                                        Representative,</p>
                                    <p style="line-height:100%">Customer Care Number</p>
                                    <p style="line-height:100%">For All Regions – 080-39416600</p>
                                    <ul>
                                        <li>Monday through Friday 10:00 a.m. – 06:30 p.m.</li>
                                        <li>Saturday 10:00 a.m. – 01:30 p.m.</li>
                                        <li>Sunday (Closed)</li>
                                    </ul>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-size:11px"><i></i>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                    <div style="font-size:1.2rem;padding-top:5px"><i>* Inclusive of all taxes </i></div>
                    <div style="font-size:1.2rem;padding-top:5px"><i>Disclaimer : The product recommendations are purely
                            based on the inputs provided by the user and does not create any binding requirement to
                            purchase the said products. </i></div>
                </div>
            </div>
        </div>
    </div>
</body>