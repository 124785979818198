import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';
import * as $ from 'jquery'
import { RestApiService } from './rest-api.service';
import { element } from 'protractor';

@Component({
  selector: 'app-realage',
  templateUrl: './realage.component.html',
  styleUrls: ['./realage.component.scss']
})
export class RealageComponent implements OnInit {
  negative: boolean =false;
  positive: boolean =false;
  currentAge=0;
  realAgeSelectedBack:boolean =true;
  realAgeSelectedNext:boolean =true;
  skinage=0;
  greenLine: boolean;
  yellowLine: boolean;
  skinAgeValue=[]
  sum=0;
  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService, private restApi: RestApiService) { 

  }

  ngOnInit(): void {
    this.ageDifference()
  }
 realAgeBack()
 {
this.route.navigate(['assessment/12'])
 }
 realAgeNext()
 {
this.route.navigate(['recommendations'])

let Data = this.dataservice.finalDataSurvey()
// console.log(this.dataservice.finalDataSurvey())

// this.restApi.getUserSkinDetails(Data).subscribe(data=>{
//   console.log(data)
//   localStorage.clear()
// })
//this.dataservice.getUserSkinDetails(Data).subscribe(data=>{
 


//this.restApi.getUserSkinDetails(Data)
 }
ageDifference()
{
 this.skinAgeValue=[]
 this.currentAge=Number(localStorage.getItem('difference'))
 this.skinAgeValue.push(Number(localStorage.getItem('sunProtectValue')))
 this.skinAgeValue.push(Number(localStorage.getItem('sunExposeValue')))
 this.skinAgeValue.push(Number(localStorage.getItem('skinCareValue')))
 this.skinAgeValue.push(Number(localStorage.getItem('antiskinCareValue')))
 this.skinAgeValue.push(Number(localStorage.getItem('stressValue')))
 this.skinAgeValue.push(Number(localStorage.getItem('sleepValue')))
 this.skinAgeValue.push(Number(localStorage.getItem('exerciseValue')))
 this.skinAgeValue.push(Number(localStorage.getItem('pollutionValue')))
 this.skinAgeValue.push(Number(localStorage.getItem('waterValue')))
//  console.log(this.skinAgeValue)


 for(let i=0; i<this.skinAgeValue.length; i++)
 {
   this.sum=this.sum+this.skinAgeValue[i]
 }
//  console.log(this.sum)
 if(this.sum<-3)
 {
   this.sum=-3
 }
// console.log(this.sum)
//  console.log(this.currentAge)
//  console.log(this.skinage)
 this.skinage=this.currentAge+this.sum
//  console.log("skinage",this.skinage)
 localStorage.setItem('skinAge', String(this.skinage))
 //document.querySelector('.ageline').style.left = left_val;
 
// $('.ageline').css({'left':this.currentAge + '%'});
// $('.greenline').css({'left':'40%'});
//  if(this.skinage== this.currentAge)
//  {
//    this.positive=true
//    $('.greenline').css({'left':this.skinage + '%'});
//  }else{
//    this.negative=true;
//  }
 if(this.skinage<=this.currentAge )
 {
  
  this.greenLine=true
  this.positive=true
  if(this.currentAge < 30){
    
    $('.ageline').css({'left':(this.currentAge-4)+'%'});
    //$('.chip').css({'left': (this.skinage)+'%'});
    let elem: HTMLElement = document.getElementById('greenline');
    elem.style.left=(this.skinage-4)+'%';
    elem.style.display="block"

    let elem1: HTMLElement = document.getElementById('chip');
    elem1.style.left=(this.skinage-4)+'%';

    let elem2: HTMLElement = document.getElementById('tooltip');
    elem2.style.left=(this.skinage-4)+'%';
  }
  else if(this.currentAge < 50 && this.currentAge > 30){
   
    $('.ageline').css({'left':(this.currentAge-3) + '%'});
    //$('.chip').css({'left': (this.skinage)+'%'});
    let elem: HTMLElement = document.getElementById('greenline');
    elem.style.left=(this.skinage-3)+'%';
    elem.style.display="block"

    let elem1: HTMLElement = document.getElementById('chip');
    elem1.style.left=(this.skinage-3)+'%';

    let elem2: HTMLElement = document.getElementById('tooltip');
    elem2.style.left=(this.skinage-3)+'%';
  }

  else{
   
    $('.ageline').css({'left':(this.currentAge-1) + '%'});
    //$('.chip').css({'left': (this.skinage)+'%'});
    let elem: HTMLElement = document.getElementById('greenline');
    elem.style.left=(this.skinage-1)+'%';
    elem.style.display="block"

    let elem1: HTMLElement = document.getElementById('chip');
    elem1.style.left=(this.skinage-1)+'%';

    let elem2: HTMLElement = document.getElementById('tooltip');
    elem2.style.left=(this.skinage-1)+'%';
  }

 }
 
 if(this.skinage > this.currentAge)
 {
  // console.log("yellow")
  this.yellowLine=true
  
  this.negative=true
  if(this.currentAge < 30){

  $('.ageline').css({'left':(this.currentAge-4) + '%'});
 // $('.yellowline').css({'left':'25%'});
  
  //let myElement: HTMLElement= document.getElementById("yellowline");
  let elem: HTMLElement = document.getElementById('yellowline');
  elem.style.left=(this.skinage-4)+'%'
  elem.style.display="block"

  let elem1: HTMLElement = document.getElementById('chip');
  elem1.style.left=(this.skinage-4)+'%';

  let elem2: HTMLElement = document.getElementById('tooltip');
  elem2.style.left=(this.skinage-4)+'%';

  }
  else if(this.currentAge < 50 && this.currentAge > 30){
  
  $('.ageline').css({'left':(this.currentAge-3) + '%'});
 // $('.yellowline').css({'left':'25%'});
  
  //let myElement: HTMLElement= document.getElementById("yellowline");
  let elem: HTMLElement = document.getElementById('yellowline');
  elem.style.left=(this.skinage-3)+'%'
  elem.style.display="block"

  let elem1: HTMLElement = document.getElementById('chip');
  elem1.style.left=(this.skinage-3)+'%';

  let elem2: HTMLElement = document.getElementById('tooltip');
  elem2.style.left=(this.skinage-3)+'%';
  }
  else{
    $('.ageline').css({'left':(this.currentAge-1) + '%'});
  // $('.yellowline').css({'left':'25%'});
    
    //let myElement: HTMLElement= document.getElementById("yellowline");
    let elem: HTMLElement = document.getElementById('yellowline');
    elem.style.left=(this.skinage-1)+'%'
    elem.style.display="block"

    let elem1: HTMLElement = document.getElementById('chip');
    elem1.style.left=(this.skinage-1)+'%';

    let elem2: HTMLElement = document.getElementById('tooltip');
    elem2.style.left=(this.skinage-1)+'%';
  }

  //setStyleAttribute(elem, {'font-size':'12px', color: 'red', 'margin-top': '5px'});
  //document.getElementById("yellowline").style.left='25%';
  //document.getElementsByClassName("yellowline")[0]
// myElement.style.backgroundColor = "#D93600";
 }
}
}
