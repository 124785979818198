<head>

  <title>Artistry</title>
  <meta charset="utf-8">
  <meta name="viewport" content="width=device-width, initial-scale=1">
  <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
  <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
  <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
</head>
<body>
<nav class="navbar">
  <div class="container">
    <a href="home"><img src="../../assets/images/artistry_logo_header.png" height="45px"/></a>
  </div>
</nav>

<div class="container page-content">
<div class="row">
  <div class="col-sm-12 col-md-8">
    <div >
        <div class="question-section">
            <h3>Do you exercise a minimum of 90 minutes per week?</h3>
        </div>
    
        <div class="answer-section" >
            <div class="radio-design">
                <div class="radio">
                    <input type="radio" name="name2" id="optionsRadios5" value="Never"  [(ngModel)]="exerciseOptionSelected">
                    <label for="optionsRadios5" (click)="exerciseOption1()">
                        Never
                    </label>
                </div>
                <div class="radio">
                    <input type="radio" name="name2" id="optionsRadios6" value="Rarely" [(ngModel)]="exerciseOptionSelected">
                    <label for="optionsRadios6" (click)="exerciseOption2()">
                        Rarely
                    </label>
                </div>
                <div class="radio">
                    <input type="radio" name="name2" id="optionsRadios7" value="Sometimes" [(ngModel)]="exerciseOptionSelected">
                    <label for="optionsRadios7" (click)="exerciseOption3()">
                        Sometimes
                    </label>
                </div>
                <div class="radio">
                    <input type="radio" name="name2" id="optionsRadios8" value="Always" [(ngModel)]="exerciseOptionSelected">
                    <label for="optionsRadios8" (click)="exerciseOption4()">
                        Always
                    </label>
                </div>
            </div>
        </div>
        <div class="next-back"  >
            <button class="float-left back" *ngIf="exerciseSelectedBack"(click)="exerciseBack()">Back</button>
            <button class="float-right next" *ngIf="exerciseSelectedNext" (click)="exerciseNext()">Next</button>
        </div>
    </div>

</div>
<div class="col-sm-12 col-md-4">
    <div class="progress">
        <div class="progress-bar" role="progressbar" aria-valuenow="78" aria-valuemin="0" aria-valuemax="100" style="width:78%">
          <span>78%</span>
        </div>
      </div>
</div>
</div>
</div>

<footer class="container-fluid text-center">
    <div class="col-sm-7 p-20-0">
        <p>
            Amway India
        </p>
        <p>
            Copyright © 2018 Amway India. All rights are Reserved
        </p>
    </div>
    <div class="col-sm-5"><img src="../../assets/images/Art_Log_3D_05C_sRGB_white.png" height="65px" class="img-responsive m-a"/></div>
</footer>

</body>

