import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-stress',
  templateUrl: './stress.component.html',
  styleUrls: ['./stress.component.scss']
})
export class StressComponent implements OnInit {
  stressSelectedBack:boolean= true;
  stressSelectedNext:boolean = false;

  stressOptionSelected:any;
  stress: {};
  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    this.stress={}
    this.stress['questionid']=localStorage.getItem('stressQustionId')
    this.stress['question']=localStorage.getItem('stressQuestion')
   this.stress['answer']=localStorage.getItem('stressAnswer')
   this.stress['stressValue']=localStorage.getItem('stressValue')
   this.stressOptionSelected=localStorage.getItem('stressAnswer')
   this.dataservice.stressdata=this.stress
   if(localStorage.getItem('stressAnswer')=="Never" || localStorage.getItem('stressAnswer')=="Rarely" || localStorage.getItem('stressAnswer')=="Sometimes" ||localStorage.getItem('stressAnswer')=="Always")
   {
     this.stressSelectedNext=true
   }
   else{
     this.stressSelectedNext=false
   }
  
  }
  stressOption1()
  {
    localStorage.setItem('stressQustionId', "8")
    localStorage.setItem('stressQuestion', "How often do you experience high stress?")
    localStorage.setItem('stressAnswer', "Never")
    localStorage.setItem('stressValue', "-1")

    this.stressOptionSelected="Never"    
    this.stressSelectedNext=true;
  }
  stressOption2()
  {
    localStorage.setItem('stressQustionId', "8")
    localStorage.setItem('stressQuestion', "How often do you experience high stress?")
    localStorage.setItem('stressAnswer', "Rarely")
    localStorage.setItem('stressValue', "-1")
    this.stressOptionSelected="Rarely"    
    this.stressSelectedNext=true;
  }
  stressOption3()
  {
    localStorage.setItem('stressQustionId', "8")
    localStorage.setItem('stressQuestion', "How often do you experience high stress?")
    localStorage.setItem('stressAnswer', "Sometimes")
    localStorage.setItem('stressValue', "0")
    this.stressOptionSelected="Sometimes"    
    this.stressSelectedNext=true;
  }
  stressOption4()
  {
    localStorage.setItem('stressQustionId', "8")
    localStorage.setItem('stressQuestion', "How often do you experience high stress?")
    localStorage.setItem('stressAnswer', "Always")
    localStorage.setItem('stressValue', "1")
    this.stressOptionSelected="Always"    
    this.stressSelectedNext=true;
  }
  stressBack()
  {
    this.route.navigate(['assessment/7'])
  }
  stressNext()
  {
    this.route.navigate(['assessment/9'])
    this.stress={}
    this.stress['questionid']=localStorage.getItem('stressQustionId')
    this.stress['question']=localStorage.getItem('stressQuestion')
   this.stress['answer']=localStorage.getItem('stressAnswer')
   this.stressOptionSelected=localStorage.getItem('stressAnswer')
   this.dataservice.stressdata=this.stress

  }
}
