import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  acceptanceForm: FormGroup
  submitted: boolean;


  constructor(private fb: FormBuilder, private route: Router) { }

  ngOnInit(): void {
    this.initializeAcceptence()
  }
 
  
  initializeAcceptence()
  {
    this.acceptanceForm= this.fb.group({
      acceptCheck: new FormControl('')
    })
  }
  acceptSubmit()
  {
    this.submitted=true
    localStorage.clear()

    if(this.acceptanceForm.controls['acceptCheck'].value==true)
    {
      this.route.navigate(['assessment']) 
    }

  }
  
  
}
