<head>
    <title>Artistry</title>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/css/bootstrap.min.css">
    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.5.1/jquery.min.js"></script>
    <script src="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.1/js/bootstrap.min.js"></script>
  
  </head>
  
  <body>
  
    <nav class="navbar">
      <div class="container">
        <a href="home"><img src="../../assets/images/artistry_logo_header.png" height="45px"/></a>
      </div>
    </nav>
  
    <div class="container page-content">
      <div class="row">
        <div class="col-sm-12 col-md-12">
            <p class="m-t-20">
                I have read, accepted and agreed to comply with the Terms of Use and Privacy Policy.
                </p>
                <p>
By using this application, you agree to be bound by these <a href="http://www.amway.in/store/amway/en/INR/static-pages/termsOfUse" style="color: #a89275;" >website terms of use </a>  between you and Amway. In addition to these terms of use, you agree to be bound by <a href="http://www.amway.in/store/amway/en/INR/static-pages/privacyPolicy" style="color: #a89275;" >Website Privacy Notice </a>  & <a href="http://www.amway.in/store/amway/en/INR/static-pages/ABO_PCPrivacyPolicy" style="color: #a89275;" >ABOs & PCs Privacy Policy </a> .
            </p>
            <h3>
                Disclaimer :
            </h3>

            <p>
                To the fullest extent permitted by applicable law, Amway disclaims any and all responsibility or liability for the accuracy, content, completeness, legality, reliability, operability or availability of information or material displayed in the application. Amway disclaims any responsibility for any harm resulting from information or material through the application.
            </p>
            <p>
                The application and all materials, information, products and services included in the application are provided "as is," with no warranties whatsoever. Amway and its affiliates, officers, employees, agents, partners and licensors expressly disclaim to the fullest extent permitted by law :
            </p>
            <p>
                (i) all express, implied and statutory warranties, including without limitation, warranties of merchantability, fitness for a particular purpose and non-infringement of proprietary rights;
            </p>

            <p>
                (ii) any warranties regarding the security, reliability, timeliness, and performance of the application;
            </p>
            <p>
                (iii) any warranties for any information or advice obtained through the application;
            </p>
            <p>
                (iv) any warranties for services or goods received through or advertised on the application or received through any information provided by the application, as well as for any information or advice received through any information provided in the application. You understand and agree that you download or otherwise obtain or access material or information through the use of the application at your own discretion and risk and that you will be solely responsible for any damages or loss of data to your computer system or to your mobile phone or device that results from the download of such material or information.
            </p>



        </div>
      </div>
    </div>
  
  
  
  
    <footer class="container-fluid text-center">
      <div class="col-sm-7 p-20-0">
        <p>
          Amway India
        </p>
        <p>
          Copyright © 2018 Amway India. All rights are Reserved
        </p>
      </div>
      <div class="col-sm-5"><img src="../../assets/images/Art_Log_3D_05C_sRGB_white.png" height="65px"
          class="img-responsive m-a" /></div>
    </footer>
  
  </body>