import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { NavigationStart, Router } from '@angular/router';
import { Location } from '@angular/common'
import { dataService } from '../dataservice.service'
@Component({
  selector: 'app-assessment',
  templateUrl: './assessment.component.html',
  styleUrls: ['./assessment.component.scss']
})
export class AssessmentComponent implements OnInit {
  

 



  questionsForm: FormGroup;
  submitted: boolean;


  birthYear = []
  ageSelected: boolean = false;
  ageAssessment: boolean = true;
  ageselectedValue: any;


 
  agedate: any;
  date = new Date()
  ageDate: {};
  


  

  constructor(private fb: FormBuilder, private route: Router, private location: Location, private dataservice: dataService) {
   
   }


  ngOnInit(): void {
    this.ageDate={}
    this.ageDate['questionid']=localStorage.getItem('birthQuestionId')
    this.ageDate['question']=localStorage.getItem('birthQuestion')
    this.ageDate['anwser']=localStorage.getItem('birthAnwser')
    this.ageDate['difference']=localStorage.getItem('difference')
    this.dataservice.age=this.ageDate
    
    
    this.yearSelection()
    this.initializeQuestions()
    this.assessmentReview()

    
   
  }
  initializeQuestions() {
    if(localStorage.getItem('birthAnwser')==null)
    {
      localStorage.setItem('birthAnwser', "Select Birth Year" )
      this.ageselectedValue=localStorage.getItem('birthAnwser')
      this.ageSelected=false
    }
    else 
    {
      // this.questionsForm = this.fb.group({
      
      //   birthAge: localStorage.getItem('birthAge'),
      //   skinCondition: '',
      //   skinFeel:''
      // })
      this.ageselectedValue=localStorage.getItem('birthAnwser')
      this.ageSelected=true
    }
   
  }
  /***************** BIRTH AGE SELECTION *******************/
  yearSelection() {
    
    for (let i = 1930; i <= this.date.getFullYear()-18; i++) {
      this.birthYear.push(i)
    }
  }

  ageSelection(event) {
    
    this.agedate=event.target.value
    
    
    let difference=this.date.getFullYear()-this.agedate
    
    
    localStorage.setItem('birthQuestion', "Please enter your birth year.")
    localStorage.setItem('birthQuestionId', "1")
    localStorage.setItem('difference', difference.toString() )
    localStorage.setItem('birthAnwser', this.agedate)

    this.ageselectedValue=localStorage.getItem('birthAnwser')
    if (this.ageselectedValue> 0) {
      this.ageSelected = true

    } else {
      this.ageSelected = false
    }
  }
  ageSelectionNext() {
    this.ageDate={}
    this.ageDate['questionid']=localStorage.getItem('birthQuestionId')
    this.ageDate['question']=localStorage.getItem('birthQuestion')
    this.ageDate['anwser']=localStorage.getItem('birthAnwser')
    this.ageDate['difference']=localStorage.getItem('difference')
   
    this.dataservice.age=this.ageDate
   this.route.navigate(['assessment/2'])
    this.ageAssessment = false
    if ( this.ageselectedValue > 0) {
      this.ageSelected = true

    } else {
      this.ageSelected = false
    }
   // this.skinConditionAssessment= true
   
  }
 





  assessmentReview() {
    this.submitted = true

  }


}
