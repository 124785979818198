import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AntiskincareComponent } from './antiskincare/antiskincare.component';

import { AssessmentComponent } from './assessment/assessment.component';
import { ExerciseComponent } from './exercise/exercise.component';
import { HomeComponent } from './home/home.component';
import { PollutionComponent } from './pollution/pollution.component';
import { RealageComponent } from './realage/realage.component';
import { RecommendationsComponent } from './recommendations/recommendations.component';
import { SkincareComponent } from './skincare/skincare.component';
import { SkinconditionComponent } from './skincondition/skincondition.component';
import { SkinfeelComponent } from './skinfeel/skinfeel.component';
import { SleepComponent } from './sleep/sleep.component';
import { StressComponent } from './stress/stress.component';
import { SunexposeComponent } from './sunexpose/sunexpose.component';
import { SunprotectionComponent } from './sunprotection/sunprotection.component';
import { TermsComponent } from './terms/terms.component';
import { WaterComponent } from './water/water.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'assessment',
    component: AssessmentComponent,
  },
  {
    path: 'assessment/2',
    component: SkinconditionComponent

  },
  {
    path: 'assessment/3',
    component: SkinfeelComponent

  },
  {
    path: 'assessment/4',
    component: SunprotectionComponent
  },
  {
    path: 'assessment/5',
    component: SunexposeComponent
  },
  {
    path: 'assessment/6',
    component: SkincareComponent
  },
  {
    path: 'assessment/7',
    component: AntiskincareComponent
  },
  {
    path: 'assessment/8',
    component: StressComponent
  },
  {
    path: 'assessment/9',
    component: SleepComponent
  },
  {
    path: 'assessment/10',
    component: ExerciseComponent
  },

  {
    path: 'assessment/11',
    component: PollutionComponent
  },
  {
    path: 'assessment/12',
    component: WaterComponent
  },
  {
    path: 'assessment/13',
    component: RealageComponent
  },


  {
    path: 'recommendations',
    component: RecommendationsComponent

  },
  {
    path: 'terms-of-use-and-privacy-policy',
    component: TermsComponent
  },



  {
    path: '',
    redirectTo: '',
    pathMatch: 'full'
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
