import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { dataService } from '../dataservice.service';

@Component({
  selector: 'app-sleep',
  templateUrl: './sleep.component.html',
  styleUrls: ['./sleep.component.scss']
})
export class SleepComponent implements OnInit {
  sleepOptionSelected: any;
  sleep: {};
  sleepSelectedBack: boolean= true;
  sleepSelectedNext:boolean=false;
  constructor(private fb: FormBuilder, private route: Router, private dataservice: dataService) { }

  ngOnInit(): void {
    this.sleep={}
    this.sleep['questionid']=localStorage.getItem('sleepQustionId')
    this.sleep['question']=localStorage.getItem('sleepQuestion')
   this.sleep['answer']=localStorage.getItem('sleepAnswer')
   this.sleep['sleepValue']=localStorage.getItem('sleepValue')
   this.sleepOptionSelected=localStorage.getItem('sleepAnswer')
   this.dataservice.sleepdata=this.sleep
   if(localStorage.getItem('sleepAnswer')=="Never" || localStorage.getItem('sleepAnswer')=="Rarely" || localStorage.getItem('sleepAnswer')=="Sometimes" ||localStorage.getItem('sleepAnswer')=="Always")
   {
     this.sleepSelectedNext=true
   }
   else{
     this.sleepSelectedNext=false
   }
   
  }
  sleepOption1()
  {
    localStorage.setItem('sleepQustionId', "9")
    localStorage.setItem('sleepQuestion', "How often do you get 7 to 8 hours of sleep at night?")
    localStorage.setItem('sleepAnswer', "Never")
    localStorage.setItem('sleepValue', "2")
    this.sleepOptionSelected="Never"    
    this.sleepSelectedNext=true;
  }
  sleepOption2()
  {
    localStorage.setItem('sleepQustionId', "9")
    localStorage.setItem('sleepQuestion', "How often do you get 7 to 8 hours of sleep at night?")
    localStorage.setItem('sleepAnswer', "Rarely")
    localStorage.setItem('sleepValue', "1")
    this.sleepOptionSelected="Rarely"    
    this.sleepSelectedNext=true;
  }
  sleepOption3()
  {
    localStorage.setItem('sleepQustionId', "9")
    localStorage.setItem('sleepQuestion', "How often do you get 7 to 8 hours of sleep at night?")
    localStorage.setItem('sleepAnswer', "Sometimes")
    localStorage.setItem('sleepValue', "0")
    this.sleepOptionSelected="Sometimes"    
    this.sleepSelectedNext=true;
  }
  sleepOption4()
  {
    localStorage.setItem('sleepQustionId', "9")
    localStorage.setItem('sleepQuestion', "How often do you get 7 to 8 hours of sleep at night?")
    localStorage.setItem('sleepAnswer', "Always")
    localStorage.setItem('sleepValue', "-1")
    this.sleepOptionSelected="Always"    
    this.sleepSelectedNext=true;
  }

  sleepBack()
  {
this.route.navigate(['assessment/8'])
  }
  sleepNext()
  {
this.route.navigate(['assessment/10'])
this.sleep={}
    this.sleep['questionid']=localStorage.getItem('sleepQustionId')
    this.sleep['question']=localStorage.getItem('sleepQuestion')
   this.sleep['answer']=localStorage.getItem('sleepAnswer')
   this.sleepOptionSelected=localStorage.getItem('sleepAnswer')
   this.dataservice.sleepdata=this.sleep
  }

}
